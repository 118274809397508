import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { PaymentDeclinedComponent } from './pages/checkout/steps/third-step/payment-declined/payment-declined.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'nosotros/por-que',
      data: { page: 'aboutUs' },
      loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutModule),
    },
    {
      path: 'cotizar',
      data: { page: 'home' },
      loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
    },
    {
      path: 'pago',
      data: { page: 'paymentConfirmation' },
      loadChildren: () =>
        import('./pages/payment-confirmation/payment-confirmation.module').then((m) => m.PaymentConfirmationModule),
    },
    {
      path: 'confirmar-pago',
      data: { page: 'paymentConfirmation' },
      loadChildren: () =>
        import('./pages/process-payment-kushki/process-payment-kushki.module').then(
          (m) => m.ProcessPaymentKushkiModule
        ),
    },
    {
      path: 'consulta',
      data: { page: 'querySystem' },
      loadChildren: () => import('./pages/query-system/query-system.module').then((m) => m.QuerySystemModule),
    },
    {
      path: 'cotizador-de-proveedores',
      data: { page: 'supplierQuote' },
      loadChildren: () => import('./pages/supplier-quote/supplier-quote.module').then((m) => m.SupplierQuoteModule),
    },
    {
      path: 'proveedores',
      data: { page: 'sponsors' },
      loadChildren: () => import('./pages/sponsors/sponsors.module').then((m) => m.SponsorsModule),
    },
    {
      path: 'planes',
      data: { page: 'coveragePlans' },
      loadChildren: () => import('./pages/plans/plans.module').then((m) => m.PlansModule),
    },
    {
      path: 'preguntas-frecuentes',
      data: { page: 'faq' },
      loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqModule),
    },
    {
      path: 'pago-rechazado/:hashCode',
      data: { page: 'paymentDeclined' },
      component: PaymentDeclinedComponent,
    },
    {
      path: 'pagina-no-encontrada',
      data: { page: 'notFound' },
      loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
    },
    {
      path: 'politicas-de-privacidad',
      data: { page: 'politicas' },
      loadChildren: () => import('./pages/policies/policies.module').then((m) => m.PoliciesModule),
    },
    {
      path: 'condiciones-de-uso',
      data: { page: 'condiciones' },
      loadChildren: () => import('./pages/conditions/conditions.module').then((m) => m.ConditionsModule),
    },
    {
      path: 'reclamos',
      data: { page: 'claims' },
      loadChildren: () => import('./pages/claims/claims.module').then((m) => m.ClaimsModule),
    },
    {
      path: 'contacto',
      data: { page: 'contacto' },
      loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule),
    },
    {
      path: 'ayuda-documento',
      data: { page: 'document-help' },
      loadChildren: () => import('./pages/document-help/document-help.module').then((m) => m.DocumentHelpModule),
    },
  ]),
  {
    path: 'insurance-documents',
    data: { page: 'home' },
    loadChildren: () => import('./pages/documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: 'quotation-result',
    data: { page: 'querySystem' },
    loadChildren: () => import('./pages/quotation-result/quotation-result.module').then((m) => m.QuotationResultModule),
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
