<div class="complete">
  <div class="loading-carousel" *ngIf="!images"></div>
  <ngb-carousel *ngIf="images" id="carousel_home" aria-activedescendant="group">
    <ng-template ngbSlide *ngFor="let image of images">
      <a [href]="image.RedirectUrl" target="_blank" *ngIf="image.RedirectUrl; else elseBlock">
        <div class="picsum-img-wrapper">
          <img [src]="image.Url" alt="Slide home" />
        </div>
      </a>
      <ng-template #elseBlock>
        <div class="picsum-img-wrapper">
          <img [src]="image.Url" alt="Slide home" />
        </div>
      </ng-template>
    </ng-template>
  </ngb-carousel>
  <div class="container-fluid">
    <div class="row m-0">
      <div class="row">
        <div class="col-md-6 cont-quote justify-content-center pb-4 align-items-center flex-column d-flex">
          <app-quote></app-quote>
        </div>
      </div>
    </div>
  </div>
  <app-info-assistance></app-info-assistance>
  <app-info-plans></app-info-plans>
  <app-our-services></app-our-services>
  <!-- <app-cards-promo></app-cards-promo> -->
  <!-- <app-carousel-info></app-carousel-info> -->
  <app-comments></app-comments>
  <!-- <app-payment-methods-info></app-payment-methods-info> -->
  <app-faq></app-faq>
</div>
