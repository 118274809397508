import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ParameterByDomainService } from '@app/@core/services';
import { GoogleAnaliticsService } from '@app/@core/services/analytics/google-analitics.service';
import { StorageService } from '@app/@core/services/storage/storage.service';

@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss'],
})
export class NavbarMenuComponent implements OnInit {
  public isCollapsed: boolean = true;
  public currentRoute: string = '';

  urlBlog: string;
  linkWhats: string | undefined;
  linkEmergency: string | undefined;
  showHeader: boolean = true;

  constructor(
    private _storage: StorageService,
    private _analytics: GoogleAnaliticsService,
    private router: Router,
    private _parameterDomain: ParameterByDomainService
  ) {
    const topLevelDomain = this._storage.getItem('topLevelDomain');
    const validBlog: string[] = ['co', 'cl', 'pe', 've', 'cr', 'ec', 'mx', 'om'];
    this.urlBlog = validBlog.includes(topLevelDomain!)
      ? 'https://' + window.location.host + '/blog'
      : 'https://traveler-assistance.com/blog';
    this.validateUrlstartsWith();
    console.log(topLevelDomain);
    console.log(this.urlBlog, validBlog.includes(topLevelDomain!));

    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    window.addEventListener('resize', this.scrollEvent, true);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.validateUrlstartsWith();
      }
    });
  }

  validateUrlstartsWith() {
    var slug = false;
    const notAllowed = ['/cotizar'];
    for (const item of notAllowed) {
      slug = this.router.url.startsWith(item);
      if (slug) {
        if (item == '/cotizar' && this.router.url.endsWith('/pago-rechazado')) {
          slug = !slug;
        }
        break;
      }
    }
    this.showHeader = !slug;
  }

  scrollEvent = (event: any): void => {
    if (this.showHeader && this.currentRoute != '/cotizador-de-proveedores') {
      const navbar = document.querySelector('.menu-header') as HTMLElement;
      const navbarElement = document.querySelector('.navbar-nav') as HTMLElement;
      const brandImage = document.querySelector('#brand') as HTMLImageElement;
      const elementoDeReferencia = document.getElementById('ref') as HTMLElement;
      const buttonEmp = document.querySelector('.button-emp') as HTMLElement;
      const referenciaTop = elementoDeReferencia.getBoundingClientRect().top;

      const scrollPosition = window.scrollY;
      // Verifica si la posición de desplazamiento es mayor o igual a 100 píxeles
      if (referenciaTop <= -100) {
        // Agrega la clase al elemento
        navbar.classList.add('init');
        navbarElement.classList.add('init');
        brandImage.src =
          'https://res.cloudinary.com/pervolare-org/image/upload/v1698435900/TAS/home/logo-tas-white_et6rf0.svg';
        buttonEmp.classList.add('init');
      } else {
        // Quita la clase del elemento
        navbar.classList.remove('init');
        navbarElement.classList.remove('init');
        brandImage.src =
          'https://res.cloudinary.com/pervolare-org/image/upload/v1698425615/TAS/home/geop3aigtgwhisftzl7r.svg';
        buttonEmp.classList.remove('init');
      }
    }
  };

  ngAfterViewInit() {
    this._parameterDomain.getParameterInfoFooter(this._storage.getTopLevelDomain()).subscribe((info) => {
      this.linkWhats = info[0].BtnWhatsAppSales;
      this.linkEmergency = info[0].BtnEmergency;
    });
  }

  public contactEvent(): void {
    /**Google Analytics Event */
    this._analytics.addEvent('whatsapp');
  }
}
