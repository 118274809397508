import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '@app/@core/api/models/base/base-response.dto';
import { discountCode } from '@app/@core/api/models/discount-code';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ValidateCodeService {
  constructor(private http: HttpClient) {}

  GetByValue(id: string): Observable<discountCode[]> {
    return this.http
      .get<BaseResponse<discountCode[]>>(`${environment.serverUrl}/discountcode/getByValue/${id}`)
      .pipe(map((d) => d.result));
  }
}
