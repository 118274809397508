import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { SharedModule } from '@shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { QuoteModule } from '@app/@modules';
import { PaymentMethodsInfoComponent } from './components/payment-methods-info/payment-methods-info.component';
import { CardsPromoComponent } from './components/cards-promo/cards-promo.component';
import { InfoAssistanceComponent } from './components/info-assistance/info-assistance.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { CarouselInfoComponent } from './components/carousel-info/carousel-info.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { CommentsComponent } from './components/comments/comments.component';
import { FaqComponent } from './components/faq/faq.component';
import { CarouselModule } from 'primeng/carousel';
import { InfoPlansComponent } from './components/info-plans/info-plans.component';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    Angulartics2Module,
    HomeRoutingModule,
    QuoteModule,
    NgbCarouselModule,
    NgbAccordionModule,
    CarouselModule,
  ],
  declarations: [
    HomeComponent,
    PaymentMethodsInfoComponent,
    CardsPromoComponent,
    InfoAssistanceComponent,
    OurServicesComponent,
    CarouselInfoComponent,
    CommentsComponent,
    FaqComponent,
    InfoPlansComponent,
  ],
})
export class HomeModule {}
