import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Socket } from 'ngx-socket-io';

@Injectable()
export class PhoneValidatorService extends Socket {
  constructor() {
    super({ url: environment.sockets.phoneValidator.url });
  }
}
