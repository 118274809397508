import { DOCUMENT, Location } from '@angular/common';
import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, tap, map, find } from 'rxjs/operators';
import { TypingName, TypingProperty, TypingSEO, TypingPlanSeo } from '@shared/models/seo.model';
import { Domain, DomainSeo, PlanSeo, SeoPage } from '@app/interfaces';
import { StorageService } from '../storage/storage.service';
import { environment } from '@env/environment';
import { ParameterByDomainService } from '../parameter-by-domain/parameter-by-domain.service';
import { DOMAIN_NAME, TEST_DOMAINS } from '@app/common';

const DEFAULT_SEO_PAGE = { title: '', titleSuffix: '', descripction: '', keysword: '' };
const DEFAULT_SEO_PAGES = {
  home: DEFAULT_SEO_PAGE,
  supplierQuote: DEFAULT_SEO_PAGE,
  notFound: DEFAULT_SEO_PAGE,
  beneficios: DEFAULT_SEO_PAGE,
  aboutUs: DEFAULT_SEO_PAGE,
  quote: DEFAULT_SEO_PAGE,
  cotiza: DEFAULT_SEO_PAGE,
  politicas: DEFAULT_SEO_PAGE,
  condiciones: DEFAULT_SEO_PAGE,
  testimonials: DEFAULT_SEO_PAGE,
  faq: DEFAULT_SEO_PAGE,
  app: DEFAULT_SEO_PAGE,
  sponsors: DEFAULT_SEO_PAGE,
  querySystem: DEFAULT_SEO_PAGE,
  paymentConfirmation: DEFAULT_SEO_PAGE,
  paymentDeclined: DEFAULT_SEO_PAGE,
};

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  @ViewChild('head') head: ElementRef | undefined;

  private domains: Partial<Domain>[];

  private dataSeo: SeoPage;
  private unsubscribe$ = new Subject<void>();
  private baseUrl: string;
  private domain: string;
  private renderer: Renderer2;
  private pathname: string;
  private linksAlternate = new Array();
  private currentBook: { names: TypingName[]; propertys: TypingProperty[] };
  private headElement: any; // HTMLElement;
  private canonical: any;

  constructor(
    private ngTitle: Title,
    private ngMeta: Meta,
    private renderer2: RendererFactory2,
    private location: Location,
    private _storage: StorageService,
    private _parameterDomain: ParameterByDomainService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.renderer2.createRenderer(null, null);
    this.headElement = this.document.head;
    this.domains = [];
    this.dataSeo = DEFAULT_SEO_PAGES;
    this.baseUrl = '';
    this.domain = '';
    this.pathname = '';
    this.currentBook = { names: [], propertys: [] };
    this.setDataBase();
  }

  private setDataBase(domain?: string) {
    this.domain = domain ?? 'traveler-assistance.com';
    this.baseUrl = `https://${this.domain}`;
    this.pathname = this.location.path();
  }

  /**
   * @description Establecer etiquetas generales de SEO por pagina
   * @param page: keyof SeoPage
   * @return void
   */
  public createOpenTagsSeo(page: keyof SeoPage): void {
    this.getDataSeoBypage(page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((seo) => {
        const currentDomain = this.domains.find(
          (domain) => domain.topLevelDomain === this._storage.getTopLevelDomain()
        );
        this.setDataBase(currentDomain?.domainName);
        this.createCanonicalURL();

        this.setTags({
          title: seo.title,
          titleSuffix: seo.titleSuffix,
          description: seo.descripction,
          image: 'https://www.traveler-assistance.com/assets/img/sidebar/logo-tas.svg',
          keywords: seo.keysword,
          url: `${this.baseUrl}${this.pathname}`,
        });

        this.document.documentElement.lang = currentDomain?.lang == 'x-default' ? 'es' : currentDomain?.lang ?? '';

        const tagName: TypingName[] = [
          { name: 'robots', content: TEST_DOMAINS.includes(DOMAIN_NAME) ? 'noindex, nofollow' : 'index, follow' },
        ];

        const tagProperty: TypingProperty[] = [
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: `${this.domain} | Tienda online` },
          { property: 'fb:app_id', content: environment.fbAppId },
          { property: 'og:locale', content: currentDomain?.lang ?? '' },
        ];

        this.domains.forEach((domain) => {
          if (domain.topLevelDomain == currentDomain?.topLevelDomain) return;
          tagProperty.push({
            property: 'og:locale:alternate',
            content: domain.lang ?? '',
          });
        });

        this.setNameTags(tagName);
        this.setPropertyTags(tagProperty);
        this.createHrefLang();
        this.ngOnSubscribe();
        this.setStructuredData('reviews');
      });
  }

  /**
   * @description Establecer etiquetas de información del Plan actual
   * @return void
   */
  public createPlanTags(config: PlanSeo): void {
    this.setDataBase();
    this.createCanonicalURL();
    this.setTags({
      title: config.title,
      titleSuffix: config.titleSuffix,
      description: config.descripction,
      url: `${this.baseUrl}/${config.id}`,
      keywords: config.keysword,
    });

    const tagProperty: TypingProperty[] = [
      { property: 'og:description', content: config.descripction ?? '' },
      { property: 'og:image:alt', content: `${(config.title, config.titleSuffix)}` },
    ];

    this.setPropertyTags(tagProperty);
  }

  /**
   * @description Elimina etiquetas de información del Plan actual
   * @return void
   */
  public removePlanTags(): void {
    this.currentBook.names.forEach((tag) => this.ngMeta.removeTag(`name="${tag.name}"`));
    this.currentBook.propertys.forEach((tag) => this.ngMeta.removeTag(`property="${tag.property}"`));
    this.currentBook = { names: [], propertys: [] };
  }

  /**
   * @description Informar a Google de versiones localizadas de páginas
   * @return void
   */
  private createHrefLang(): void {
    if (this.linksAlternate.length > 0) {
      this.linksAlternate.forEach((link) => this.renderer.removeChild(this.headElement, link));
      this.linksAlternate.splice(0, this.linksAlternate.length);
    }

    this.domains.forEach((domain) => {
      const element = this.renderer.createElement('link');
      this.renderer.setAttribute(element, 'rel', 'alternate');
      this.renderer.setAttribute(element, 'hreflang', domain.lang ?? '');
      this.renderer.setAttribute(element, 'href', domain.url + this.pathname);
      this.renderer.appendChild(this.headElement, element);
      this.linksAlternate.push(element);
    });
  }

  /**
   * @description Define la una URL canónica
   * @return void
   */
  private createCanonicalURL(): void {
    const url = `${this.baseUrl}${this.pathname}`;

    if (this.canonical) {
      this.renderer.setAttribute(this.canonical, 'href', url);
    } else {
      const canonical = this.renderer.createElement('link');
      this.renderer.setAttribute(canonical, 'rel', 'canonical');
      this.renderer.setAttribute(canonical, 'href', url);
      this.renderer.appendChild(this.headElement, canonical);
      this.canonical = canonical;
    }
  }

  /**
   * @description Establecer etiquetas generales de SEO
   * @param config: TypingSEO
   * @return void
   */
  public setTags(config: TypingSEO): void {
    if (config.title) {
      this.title(config.title, config.titleSuffix);
    }
    if (config.description) {
      this.description(config.description);
    }
    if (config.image) {
      this.image(config.image);
    }
    if (config.keywords) {
      this.keywords(config.keywords);
    }
    if (config.url) {
      this.url(config.url);
    }
  }

  /**
   * @description Establecer etiqueta de nombre
   * @param name: TypingName
   * @param content: Contenido
   * @return void
   */
  private setNameTag(name: TypingName): void {
    const property = {
      name: name.name,
      content: name.content,
    };

    if (name.itemprop) {
      // tslint:disable-next-line:no-string-literal
      property['itemprop'] = name.itemprop;
    }

    if (this.ngMeta.getTag(`name="${property.name}"`)) {
      this.ngMeta.updateTag(property);
    } else {
      this.ngMeta.addTag(property);
    }
  }

  /**
   * @description Establecer etiquetas de nombre
   * @param names: [TypingName]
   * @return void
   */
  private setNameTags(names: TypingName[]): void {
    names.forEach((prop) => this.setNameTag(prop));
  }

  /**
   * @description Establecer etiqueta de propiedad
   * @param prop: TypingProperty
   * @param content: Contenido
   * @return void
   */
  private setPropertyTag(prop: TypingProperty): void {
    const property = {
      property: prop.property,
      content: prop.content,
      itemprop: '',
    };

    if (prop.itemprop) {
      // tslint:disable-next-line:no-string-literal
      property['itemprop'] = prop.itemprop;
    }

    if (property.property != 'og:locale:alternate' && this.ngMeta.getTag(`property="${property.property}"`)) {
      this.ngMeta.updateTag(property);
    } else {
      this.ngMeta.addTag(property);
    }
  }

  /**
   * @description Establecer etiquetas de propiedad
   * @param props: [TypingProperty]
   * @return void
   */
  private setPropertyTags(props: TypingProperty[]): void {
    props.forEach((prop) => this.setPropertyTag(prop));
  }

  /**
   * @description Establecer etiqueta de URL
   * @param content: string
   * @return void
   */
  private url(content: string): void {
    this.ngMeta.updateTag({ property: 'og:url', itemprop: 'url', content });
  }

  /**
   * @description Establecer etiqueta de título
   * @param title: string
   * @param titleSuffix: string
   * @return void
   */
  private title(title: string, titleSuffix?: string): void {
    const content = titleSuffix ? `${title} ${titleSuffix}` : title;
    this.ngTitle.setTitle(content);
    this.setPropertyTag({ property: 'og:title', itemprop: 'title', content });
    this.setPropertyTag({ property: 'twitter:title', itemprop: 'title', content });
  }

  /**
   * @description Establecer etiqueta de descripción
   * @param content: string
   * @return void
   */
  private description(content: string): void {
    this.setNameTag({ name: 'description', itemprop: 'description', content });
    this.setPropertyTag({ property: 'og:description', itemprop: 'description', content });
    this.setPropertyTag({ property: 'twitter:description', itemprop: 'description', content });
  }

  /**
   * @description Establecer etiqueta de imagen
   * @param content: string
   * @return void
   */
  private image(content: string): void {
    this.setPropertyTag({ property: 'twitter:image', itemprop: 'image', content });
    this.setPropertyTag({ property: 'og:image', itemprop: 'image', content });
    this.setPropertyTag({ property: 'og:image:secure_url', itemprop: 'image', content });
  }

  /**
   * @description Establecer etiqueta de palabras clave
   * @param content: string
   * @return void
   */
  private keywords(content: string): void {
    this.setNameTag({ name: 'keywords', itemprop: 'keywords', content });
  }

  /**
   * @description Se encarga de la Desuscripción de los Observables
   * @return void
   */
  private ngOnSubscribe(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @description Establece la Url base del sitio
   * @param url: string
   * @return void
   */
  private setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  /**
   * @description Retorna la información de Seo por pagina, por defecto(page: home)
   * @param page: keyof SeoPage
   * @return Observable<TypingPage>
   */
  private getDataSeoBypage(page: keyof SeoPage = 'home'): Observable<DomainSeo> {
    if (this.dataSeo.coveragePlans) {
      return of(this.dataSeo[page] ?? this.dataSeo.home);
    }

    return this._parameterDomain.getDomains().pipe(
      tap((domains) => (this.domains = domains.map(({ seoPages, ...res }) => res))),
      map((domains) => domains.find((domain) => domain.topLevelDomain === this._storage.getTopLevelDomain())),
      map((domain) => domain?.seoPages ?? DEFAULT_SEO_PAGES),
      tap((seoPages) => (this.dataSeo = seoPages)),
      map((data) => data[page])
    );
  }

  private getDataByDomain() {
    const currentDomain = this._storage.getTopLevelDomain();
    return this._parameterDomain
      .getDomains()
      .pipe(map((domains) => domains.find((domain) => domain.topLevelDomain === currentDomain)));
  }

  /**Se obtiene la informacion del Plan Seleccionado*/
  public currentPlanTags(path: string) {
    this.getDataByDomain().subscribe({
      next: (domain) => {
        const plan = domain?.seoPages.coveragePlans?.planes.find((plan) => plan.id === path);
        plan ? this.createPlanTags(plan) : this.createOpenTagsSeo('coveragePlans');
      },
    });
  }

  /**Funcion para añadir datos estructurados para FAQ*/
  public setFAQStructuredData(): void {
    this.getDataByDomain().subscribe({
      next: (domain) => {
        let script;
        script = document.createElement('script');
        script.setAttribute('id', 'faq-structured-data');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(domain?.structuredData.faq);
        document.head.appendChild(script);
      },
    });
  }

  public removeFAQStructuredData(): void {
    const script = document.getElementById('faq-structured-data');
    script?.remove();
  }

  /**Funcion para añadir datos estructurados*/
  public setStructuredData(typeData: string): void {
    this.getDataByDomain().subscribe({
      next: (domain) => {
        let script;
        script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(domain?.structuredData[typeData]);
        document.body.appendChild(script);
      },
    });
  }
}
