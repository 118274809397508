import { Pipe, PipeTransform } from '@angular/core';
import { VisaDto } from '@app/@core/api/models';

@Pipe({
  name: 'countryFilter',
})
export class CountryFilterPipe implements PipeTransform {
  transform(countries: VisaDto[], args: string): VisaDto[] {
    if (!countries) return [];
    if (!args) return countries;

    args = args.toLowerCase();

    return countries.filter((country) => {
      return country.Name?.toLowerCase().includes(args);
    });
  }
}
