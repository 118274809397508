/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TravelInsuranceCustomerDto } from '../models/travel-insurance-customer-dto';
import { TravelInsuranceCustomerDtoPagedResultDto } from '../models/travel-insurance-customer-dto-paged-result-dto';

@Injectable({
  providedIn: 'root',
})
export class TravelInsuranceCustomerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceCustomerUpdateCustomersPut
   */
  static readonly ApiServicesAppTravelInsuranceCustomerUpdateCustomersPutPath = '/quoter/UpdateCustomers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerUpdateCustomersPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerUpdateCustomersPut$Response(params: {
    Authentication: string;
    body?: Array<TravelInsuranceCustomerDto>;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerUpdateCustomersPutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerUpdateCustomersPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerUpdateCustomersPut(params: {
    Authentication: string;
    body?: Array<TravelInsuranceCustomerDto>;
  }): Observable<void> {
    return this.apiServicesAppTravelInsuranceCustomerUpdateCustomersPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceCustomerUpdateCustomerPut
   */
  static readonly ApiServicesAppTravelInsuranceCustomerUpdateCustomerPutPath =
    '/api/services/app/TravelInsuranceCustomer/UpdateCustomer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerUpdateCustomerPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerUpdateCustomerPut$Response(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerUpdateCustomerPutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerUpdateCustomerPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerUpdateCustomerPut(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<void> {
    return this.apiServicesAppTravelInsuranceCustomerUpdateCustomerPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceCustomerGetGet
   */
  static readonly ApiServicesAppTravelInsuranceCustomerGetGetPath = '/api/services/app/TravelInsuranceCustomer/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerGetGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerGetGet$Plain$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceCustomerDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerGetGetPath,
      'get'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceCustomerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerGetGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerGetGet$Plain(params: {
    Id?: number;
    Authentication: string;
  }): Observable<TravelInsuranceCustomerDto> {
    return this.apiServicesAppTravelInsuranceCustomerGetGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceCustomerDto>) => r.body as TravelInsuranceCustomerDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerGetGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerGetGet$Json$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceCustomerDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerGetGetPath,
      'get'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceCustomerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerGetGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerGetGet$Json(params: {
    Id?: number;
    Authentication: string;
  }): Observable<TravelInsuranceCustomerDto> {
    return this.apiServicesAppTravelInsuranceCustomerGetGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceCustomerDto>) => r.body as TravelInsuranceCustomerDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceCustomerGetAllGet
   */
  static readonly ApiServicesAppTravelInsuranceCustomerGetAllGetPath =
    '/api/services/app/TravelInsuranceCustomer/GetAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerGetAllGet$Plain$Response(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceCustomerDtoPagedResultDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerGetAllGetPath,
      'get'
    );
    if (params) {
      rb.query('Sorting', params.Sorting, {});
      rb.query('SkipCount', params.SkipCount, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceCustomerDtoPagedResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerGetAllGet$Plain(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<TravelInsuranceCustomerDtoPagedResultDto> {
    return this.apiServicesAppTravelInsuranceCustomerGetAllGet$Plain$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TravelInsuranceCustomerDtoPagedResultDto>) =>
          r.body as TravelInsuranceCustomerDtoPagedResultDto
      )
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerGetAllGet$Json$Response(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceCustomerDtoPagedResultDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerGetAllGetPath,
      'get'
    );
    if (params) {
      rb.query('Sorting', params.Sorting, {});
      rb.query('SkipCount', params.SkipCount, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceCustomerDtoPagedResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerGetAllGet$Json(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<TravelInsuranceCustomerDtoPagedResultDto> {
    return this.apiServicesAppTravelInsuranceCustomerGetAllGet$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TravelInsuranceCustomerDtoPagedResultDto>) =>
          r.body as TravelInsuranceCustomerDtoPagedResultDto
      )
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceCustomerCreatePost
   */
  static readonly ApiServicesAppTravelInsuranceCustomerCreatePostPath =
    '/api/services/app/TravelInsuranceCustomer/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerCreatePost$Plain$Response(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<StrictHttpResponse<TravelInsuranceCustomerDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerCreatePostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceCustomerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerCreatePost$Plain(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<TravelInsuranceCustomerDto> {
    return this.apiServicesAppTravelInsuranceCustomerCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceCustomerDto>) => r.body as TravelInsuranceCustomerDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerCreatePost$Json$Response(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<StrictHttpResponse<TravelInsuranceCustomerDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerCreatePostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceCustomerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerCreatePost$Json(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<TravelInsuranceCustomerDto> {
    return this.apiServicesAppTravelInsuranceCustomerCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceCustomerDto>) => r.body as TravelInsuranceCustomerDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceCustomerUpdatePut
   */
  static readonly ApiServicesAppTravelInsuranceCustomerUpdatePutPath =
    '/api/services/app/TravelInsuranceCustomer/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerUpdatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerUpdatePut$Plain$Response(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<StrictHttpResponse<TravelInsuranceCustomerDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerUpdatePutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceCustomerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerUpdatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerUpdatePut$Plain(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<TravelInsuranceCustomerDto> {
    return this.apiServicesAppTravelInsuranceCustomerUpdatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceCustomerDto>) => r.body as TravelInsuranceCustomerDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerUpdatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerUpdatePut$Json$Response(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<StrictHttpResponse<TravelInsuranceCustomerDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerUpdatePutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceCustomerDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerUpdatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceCustomerUpdatePut$Json(params: {
    Authentication: string;
    body?: TravelInsuranceCustomerDto;
  }): Observable<TravelInsuranceCustomerDto> {
    return this.apiServicesAppTravelInsuranceCustomerUpdatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceCustomerDto>) => r.body as TravelInsuranceCustomerDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceCustomerDeleteDelete
   */
  static readonly ApiServicesAppTravelInsuranceCustomerDeleteDeletePath =
    '/api/services/app/TravelInsuranceCustomer/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceCustomerDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerDeleteDelete$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceCustomerService.ApiServicesAppTravelInsuranceCustomerDeleteDeletePath,
      'delete'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceCustomerDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceCustomerDeleteDelete(params: { Id?: number; Authentication: string }): Observable<void> {
    return this.apiServicesAppTravelInsuranceCustomerDeleteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
