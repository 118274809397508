import { Injectable } from '@angular/core';
import { SidebarItem, TopLevelDomain } from '@app/interfaces';
import { BehaviorSubject } from 'rxjs';
import { StorageItem } from './storage-item.type';

const STORAGE_PREFIX = 'tas';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _topLevelDomain$ = new BehaviorSubject<TopLevelDomain | null>(null);
  public topLevelDomain$ = this._topLevelDomain$.asObservable();

  private _sidebar$ = new BehaviorSubject<SidebarItem | null>(null);
  public sidebar$ = this._sidebar$.asObservable();

  constructor() {
    // EL dominio se carga en el main.ts, esto es para emitir el valor del mismo
    this.setTopLevelDomain(this.getTopLevelDomain());
    //this.setSidebar({ show: false, currentUser: 'particular' });
  }

  private get storage(): Storage {
    return localStorage;
  }

  getTopLevelDomain(): TopLevelDomain {
    return this.getItem('topLevelDomain') as TopLevelDomain;
  }

  setTopLevelDomain(value: TopLevelDomain): void {
    this.setItem('topLevelDomain', value);
    this._topLevelDomain$.next(value);
  }

  setSidebar(value: SidebarItem): void {
    this.setItem('sidebarMenu', value, true);
    this._sidebar$.next(value);
  }

  clearAllLocalStorage() {
    this.storage.clear();
    this._topLevelDomain$.next(null);
  }

  getItem(name: StorageItem): string | null {
    return this.storage.getItem(`${STORAGE_PREFIX}_${name}`);
  }

  getItemJson<T>(name: StorageItem): T {
    return JSON.parse(this.storage.getItem(`${STORAGE_PREFIX}_${name}`) ?? '{}') as T;
  }

  setItem(name: StorageItem, value: any, json: boolean = false): void {
    if (json) return this.storage.setItem(`${STORAGE_PREFIX}_${name}`, JSON.stringify(value));

    return this.storage.setItem(`${STORAGE_PREFIX}_${name}`, value);
  }

  deleteItem(name: StorageItem): void {
    return this.storage.removeItem(`${STORAGE_PREFIX}_${name}`);
  }
}
