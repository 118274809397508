import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Domain, PaymentMethod, TopLevelDomain, InfoFooter } from '@app/interfaces';
import {
  GeolocationDto,
  ParameterDto,
  VisaDto,
  ParametersSlidesResponseDto,
  ParamentersFooterDto,
} from '@core/api/models';
import { GeolocationService, ParameterService, VisaService } from '@core/api/services';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ParameterByDomainService {
  private _parameter$ = new BehaviorSubject<ParameterDto | null>(null);
  public parameter$ = this._parameter$.asObservable().pipe(filter((v) => v !== null));

  private geolocation: GeolocationDto;
  private paymentMethods: PaymentMethod[];
  private domains: Domain[];
  private visas: VisaDto[];
  private infoFooter: InfoFooter[];

  constructor(
    private _parameter: ParameterService,
    private _visa: VisaService,
    private _geo: GeolocationService,
    private _storage: StorageService,
    private http: HttpClient
  ) {
    this.visas = [];
    this.domains = [];
    this.geolocation = {};
    this.paymentMethods = [];
    this.infoFooter = [];
    this._geo.rootUrl = environment.services.root;
  }

  getGTMCode(domain?: string | null): Observable<string | undefined> {
    return this.getDomains().pipe(map((code) => code.find((g) => g.topLevelDomain === domain)?.gtm));
  }

  getParametersByDomain(domain?: string | null): Observable<ParameterDto | null> {
    return this._parameter
      .apiServicesAppParameterGetParametersByDomainGet$Json(domain ?? this._storage.getTopLevelDomain())
      .pipe(
        map((parameter) => parameter.shift() ?? null),
        tap((parameter) => this._parameter$.next(parameter))
      );
  }

  getVisas(): Observable<VisaDto[]> {
    if (this.visas.length > 0) {
      return of(this.visas);
    }

    return this._visa.apiServicesAppVisaGetAllGet$Json().pipe(
      map((data: any) => JSON.parse(data)),
      tap((visas) => (this.visas = visas))
    );
  }

  getGeolocation(): Observable<GeolocationDto> {
    if (this.geolocation.Visas) {
      return of(this.geolocation);
    }

    return this._geo
      .apiServicesAppGeolocationGeolocationPost$Json({
        Authentication: '',
      })
      .pipe(
        map((data: any) => JSON.parse(data)),
        tap((geolocation) => (this.geolocation = geolocation))
      );
  }

  getDomains(): Observable<Domain[]> {
    if (this.domains.length > 0) {
      return of(this.domains);
    }

    return this.http.get<Domain[]>('assets/data/domains.json').pipe(tap((domains) => (this.domains = domains)));
  }

  getDomainByTopLevelDomain(topLevelDomain: TopLevelDomain): Observable<Domain | undefined> {
    return this.getDomains().pipe(map((domains) => domains.find((domain) => domain.topLevelDomain == topLevelDomain)));
  }

  /**
   * @Description: Obtiene los metodos de pago disponibles para todo el comercio.
   * @returns Observable<PaymentMethod[]>
   */
  getPaymentMethods(): Observable<PaymentMethod[]> {
    if (this.paymentMethods.length > 0) {
      return of(this.paymentMethods);
    }

    return this.http.get<PaymentMethod[]>('assets/data/paymentMethods.json');
  }

  /**
   *@Description: Obtiene los datos de contacto para el footer y el segundo paso de cotizacion
   */

  getInfoFooter(): Observable<InfoFooter[]> {
    if (this.infoFooter.length > 0) {
      return of(this.infoFooter);
    }

    return this.http
      .get<InfoFooter[]>('assets/data/informationFooter.json')
      .pipe(tap((infoFooter) => (this.infoFooter = infoFooter)));
  }

  getInfoFooterByTopLevelDomain(topLevelDomain: string): Observable<InfoFooter | undefined> {
    return this.getInfoFooter().pipe(
      map((infoFooter) => infoFooter.find((infoFooterU) => infoFooterU.topLevelDomain == topLevelDomain))
    );
  }

  getSlides(domain: string) {
    return this.http.get<ParametersSlidesResponseDto>(
      `${this._geo.rootUrl}/parameters/Parameters/GetBannersSlide/${domain}/3`
    );
  }

  getParameterInfoFooter(domain: string) {
    return this.http.get<ParamentersFooterDto[]>(
      `${this._geo.rootUrl}/parameters/PageComponent/GetAllConfigFooter/${domain}`
    );
  }
}
