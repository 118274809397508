import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { I18nService } from '@app/i18n';
import { ParameterByDomainService, SeoService, StorageService } from '@core/services';

declare var LiveChatWidget: any;
const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private gtm: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private _parameterDomain: ParameterByDomainService,
    private _storage: StorageService,
    private _seo: SeoService
  ) {}

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    this._storage.topLevelDomain$.subscribe((domain) => {
      this._parameterDomain.getParametersByDomain(domain).subscribe();
      this._parameterDomain.getGTMCode(domain).subscribe({
        next: (code) => (this.gtm = code ?? ''),
      });
    });

    //log.debug('init');
    this.checkUpdates();

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }

        this._seo.createOpenTagsSeo(event.page);
        this._storage.setSidebar({
          show: false,
          currentUser: title ? 'agent' : 'particular',
          activeUser: title ? 'agent' : 'particular',
        });
      });
  }

  ngAfterViewInit() {
    window.addEventListener('load', (event) => {
      this.LoadGTMScript(this.gtm);
    });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  private checkUpdates() {
    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key);
          })
        );
      });
    }
  }

  public LoadGTMScript(id: string) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    var firstScript = document.getElementsByTagName('script')[0];
    var dl = 'dataLayer' != 'dataLayer' ? '&l=' + 'dataLayer' : '';

    var script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtm.js?id=' + id + dl;
    firstScript.parentNode?.insertBefore(script, firstScript);
  }
}
