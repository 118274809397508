import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { TokenDto } from '@app/@core/api/models';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private refreshTokenInProgress: boolean = false;
  private accessTokenSubject: BehaviorSubject<TokenDto | null> = new BehaviorSubject<TokenDto | null>(null);

  constructor(private _auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.accessTokenSubject.next(null);
            return this._auth.generateToken().pipe(
              switchMap((authResponse) => {
                this.refreshTokenInProgress = false;
                this.accessTokenSubject.next(authResponse.result);
                request = request.clone({
                  setHeaders: {
                    authorization: `Bearer ${authResponse.result.access_token}`,
                  },
                });
                return next.handle(request);
              }),
              catchError((e: HttpErrorResponse) => {
                this.refreshTokenInProgress = false;
                return throwError(e);
              })
            );
          } else {
            return this.accessTokenSubject.pipe(
              filter((result) => !!result?.access_token),
              take(1),
              switchMap((token) => {
                request = request.clone({
                  setHeaders: {
                    authorization: `Bearer ${token?.access_token}`,
                  },
                });
                return next.handle(request);
              })
            );
          }
        }
        return throwError(error);
      })
    );
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }
    throw response;
  }
}
