/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ParameterDto } from '../models/parameter-dto';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ParameterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiServicesAppParameterGetParametersByDomainGet
   */
  static readonly ApiServicesAppParameterGetParametersByDomainGetPath = '/parameters/Parameters/GetParametersByDomain';

  apiServicesAppParameterGetParametersByDomainGet$Json(domain: string): Observable<ParameterDto[]> {
    let parameters = new HttpParams().set('TenantId', environment.tenantId).set('Domain', domain);

    return this.http.get<ParameterDto[]>(
      `${this.rootUrl}${ParameterService.ApiServicesAppParameterGetParametersByDomainGetPath}`,
      { params: parameters }
    );
  }
}
