import { Component, OnInit } from '@angular/core';
import { DialogQuoteComponent } from '@app/pages/plans/dialog-quote/dialog-quote.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  protected ref: any;
  constructor(private dialog: NgbModal) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.ref) this.ref.close(DialogQuoteComponent);
  }

  openDialog(): void {
    this.ref = this.dialog.open(DialogQuoteComponent, {
      centered: true,
      modalDialogClass: 'reQuoteDialog',
      size: 'lg',
    });
  }
}
