import { Injectable } from '@angular/core';
declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnaliticsService {
  constructor() {}

  public addEvent(event: string, value?: any): void {
    const content: { event: string; value?: any } = { event: event };
    if (value) content.value = value;

    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(content);
  }

  public addEcommerceEvent(event: string, data?: object, item_data?: object) {
    const items = [
      {
        item_id: 'TAS_HOME',
        item_name: 'Plan',
        item_brand: 'TAS',
        item_category: 'plan',
        currency: 'USD',
        quantity: 1,
      },
    ];

    if (item_data) Object.assign(items[0], item_data);

    const ecommerce = { currency: 'USD', items };
    if (data) Object.assign(ecommerce, data);

    window['dataLayer'].push({ ecommerce: null }); // Clear the previous ecommerce object.
    window['dataLayer'].push({ event, ecommerce });
  }
}
