import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
  responsiveOptions;
  services = [
    {
      one_img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1698898880/TAS/home/claudia_xnggab.png',
      name: 'Claudia Monrroy',
      experience: 'Una buena experiencia',
      content:
        'Me contactaron de inmediato y Manuela Mosquera fue muy clara con la información. El pago fue fácil de hacer y los documentos llegaron rápidamente.',
      style: 'mb-item-one',
      two_img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1698900131/TAS/home/raiting-claudia_t64adh.png',
    },
    {
      one_img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1698898880/TAS/home/laura_uckrjz.png',
      name: 'Laura Sanchez.',
      experience: 'Excelente atención',
      content:
        'Tuve una respuesta rápida a mi problema. No esperaba que fuera tan rápido. Me acompaño todo el tiempo que necesite ayuda y estuvo pendiente de mis dudas.',
      style: '',
      two_img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1698900130/TAS/home/raiting-laura_mast8t.png',
    },
    {
      one_img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1698898880/TAS/home/andres_pcxd8h.png',
      name: 'Andrés Orozco.',
      experience: 'Es una atención rápida',
      style: 'mb-item-two',
      content: 'Es una atención rápida, atenta y precisa, los médicos son muy amables y dan los medicamentos indicados',
      two_img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1698900130/TAS/home/raiting-andres_lw1tyc.png',
    },
  ];
  constructor() {
    this.responsiveOptions = [
      {
        breakpoint: '820px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnInit(): void {}
}
