import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountrySelectComponent } from './country-select.component';
import { NgbDropdown, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountryFilterPipe } from './pipe/country-filter.pipe';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CountrySelectComponent, CountryFilterPipe],
  exports: [CountrySelectComponent],
  imports: [CommonModule, NgbModule, NgbDropdownModule, ReactiveFormsModule],
  providers: [NgbDropdown],
})
export class CountrySelectModule {}
