<div>
  <app-navbar-menu></app-navbar-menu>
  <div class="main-content">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <ng-container *ngIf="showChat">
      <!--<app-chat></app-chat>-->
      <app-chatmb></app-chatmb>
    </ng-container>
  </div>
</div>
