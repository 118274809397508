/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VisaDto } from '../models/visa-dto';
import { VisaDtoPagedResultDto } from '../models/visa-dto-paged-result-dto';

@Injectable({
  providedIn: 'root',
})
export class VisaService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiServicesAppVisaGetAllGet
   */
  static readonly ApiServicesAppVisaGetAllGetPath = '/visa/visa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaGetAllGet$Plain$Response(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<VisaDtoPagedResultDto>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaGetAllGetPath, 'get');
    if (params) {
      rb.query('Sorting', params.Sorting, {});
      rb.query('SkipCount', params.SkipCount, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VisaDtoPagedResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaGetAllGet$Plain(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<VisaDtoPagedResultDto> {
    return this.apiServicesAppVisaGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<VisaDtoPagedResultDto>) => r.body as VisaDtoPagedResultDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaGetAllGet$Json$Response(): Observable<StrictHttpResponse<VisaDto[]>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaGetAllGetPath, 'get');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VisaDto[]>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaGetAllGet$Json(): Observable<VisaDto[]> {
    return this.apiServicesAppVisaGetAllGet$Json$Response().pipe(
      map((r: StrictHttpResponse<VisaDto[]>) => r.body as VisaDto[])
    );
  }

  /**
   * Path part for operation apiServicesAppVisaGetGet
   */
  static readonly ApiServicesAppVisaGetGetPath = '/api/services/app/Visa/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaGetGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaGetGet$Plain$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<VisaDto>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaGetGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VisaDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaGetGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaGetGet$Plain(params: { Id?: number; Authentication: string }): Observable<VisaDto> {
    return this.apiServicesAppVisaGetGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<VisaDto>) => r.body as VisaDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaGetGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaGetGet$Json$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<VisaDto>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaGetGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VisaDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaGetGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaGetGet$Json(params: { Id?: number; Authentication: string }): Observable<VisaDto> {
    return this.apiServicesAppVisaGetGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<VisaDto>) => r.body as VisaDto)
    );
  }

  /**
   * Path part for operation apiServicesAppVisaCreatePost
   */
  static readonly ApiServicesAppVisaCreatePostPath = '/api/services/app/Visa/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppVisaCreatePost$Plain$Response(params: {
    Authentication: string;
    body?: VisaDto;
  }): Observable<StrictHttpResponse<VisaDto>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaCreatePostPath, 'post');
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VisaDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppVisaCreatePost$Plain(params: { Authentication: string; body?: VisaDto }): Observable<VisaDto> {
    return this.apiServicesAppVisaCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<VisaDto>) => r.body as VisaDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppVisaCreatePost$Json$Response(params: {
    Authentication: string;
    body?: VisaDto;
  }): Observable<StrictHttpResponse<VisaDto>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaCreatePostPath, 'post');
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VisaDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppVisaCreatePost$Json(params: { Authentication: string; body?: VisaDto }): Observable<VisaDto> {
    return this.apiServicesAppVisaCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<VisaDto>) => r.body as VisaDto)
    );
  }

  /**
   * Path part for operation apiServicesAppVisaUpdatePut
   */
  static readonly ApiServicesAppVisaUpdatePutPath = '/api/services/app/Visa/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaUpdatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppVisaUpdatePut$Plain$Response(params: {
    Authentication: string;
    body?: VisaDto;
  }): Observable<StrictHttpResponse<VisaDto>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaUpdatePutPath, 'put');
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VisaDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaUpdatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppVisaUpdatePut$Plain(params: { Authentication: string; body?: VisaDto }): Observable<VisaDto> {
    return this.apiServicesAppVisaUpdatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<VisaDto>) => r.body as VisaDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaUpdatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppVisaUpdatePut$Json$Response(params: {
    Authentication: string;
    body?: VisaDto;
  }): Observable<StrictHttpResponse<VisaDto>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaUpdatePutPath, 'put');
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VisaDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaUpdatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppVisaUpdatePut$Json(params: { Authentication: string; body?: VisaDto }): Observable<VisaDto> {
    return this.apiServicesAppVisaUpdatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<VisaDto>) => r.body as VisaDto)
    );
  }

  /**
   * Path part for operation apiServicesAppVisaDeleteDelete
   */
  static readonly ApiServicesAppVisaDeleteDeletePath = '/api/services/app/Visa/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppVisaDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaDeleteDelete$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VisaService.ApiServicesAppVisaDeleteDeletePath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppVisaDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppVisaDeleteDelete(params: { Id?: number; Authentication: string }): Observable<void> {
    return this.apiServicesAppVisaDeleteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
