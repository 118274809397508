export const DOMAIN_NAME = window.location.hostname;

export const DEFAULT_TOP_LEVEL_DOMAIN = 'om';

export const DEFAULT_DOMAINS = ['traveler-assistance.com'];

export const TEST_DOMAINS = [
  'localhost',
  'staging.traveler-assistance.com',
  'new.traveler-assistance.com',
  'rebranding.traveler-assistance.com',
];

export const PATTERNS_WORDS = [
  {
    id: 1,
    pattern: /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+$/,
  },
  {
    id: 2,
    pattern: /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+\s[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+$/,
  },
  {
    id: 3,
    pattern: /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+\s[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+\s[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+$/,
  },
  {
    id: 4,
    pattern: /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+\s[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+\s[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+\s[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+$/,
  },
  {
    id: 5,
    pattern: /^\S+$/,
  },
];
