// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  currentEnviroment: 'dev',
  production: false,
  version: env.npm_package_version + '-dev',
  defaultLanguage: 'es-ES',
  supportedLanguages: ['en-US', 'es-ES'],
  serverUrl: 'https://api-management-traveler.azure-api.net',
  serverUrltasold: 'https://traveler-assistance.com',
  fenixurl: 'http://dev-fenix.traveler-assistance.com',
  blogUrl: 'https://api-blog.dev.pervolare.net',
  quoterServer: 'https://quoter-tas.pervolare.net/quoter-B2B/development',
  tenantId: 3,
  userB2C: 104,
  userB2B: 105,
  serverToken: 'c3B2LTItUEFnOVRmWjg5UVZqWjVZSDhVWkZoc3RrRHBHbmNkSkNxUUI4R2NTc1NxbXVrYjM0R0RBQVVETDlDNDdGN0Y4bg==',
  stripeApiKey: 'pk_test_P2xwm8WTUHd2yWglxWl4BzqA',
  fbAppId: '245931762957903',
  sockets: {
    phoneValidator: {
      url: 'wss://libphonenumber-qa.pervolare.net',
    },
  },
  services: {
    root: 'https://api-management-traveler.azure-api.net',
    old: 'https://tas-quotation-function.azurewebsites.net',
    geolocation: 'https://tas-geolocalization-function.azurewebsites.net',
  },
  apikeys: {
    general: '9661843830374df0b4cf7daf42cf6d44',
    payments: '45cce68b88af4e07804c981505f94bcb',
    keyAccess: 'YnVjOFF+WHVSN0hpQ2dJcU9WTmFqWEFuc3hjLTRBNFFvczRSaWJBdA==',
  },
  paypal: {
    clientId: 'AdtHlaIbhTEwhE38Szz8JRItIXqrdVF8QYXTWi7-s0rySHa7BUO3qbLJLro8XoltFh-Jgdzn55f78PMy',
  },
  kushki: {
    url: 'https://kushki.pervolare.com',
    merchantId: '75d54731704b4a2481c9f539f68d86e6',
    merchantIdTransfer: '8051e97c426b43c79e0bed5da604d76f',
  },
  dpago: {
    url: 'https://api-payment-qa.pervolare.net/directo-pago/checkout',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
