import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  get swal() {
    return Swal;
  }

  constructor() {}

  alertFire(titleText: string, text: string, icon: SweetAlertIcon) {
    return this.swal.fire({ titleText, text, icon, confirmButtonColor: '#28a745' });
  }

  toast(title: string, icon: SweetAlertIcon, position: SweetAlertPosition = 'top-end') {
    const Toast = this.swal.mixin({
      toast: true,
      position,
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', this.swal.stopTimer);
        toast.addEventListener('mouseleave', this.swal.resumeTimer);
      },
    });

    return Toast.fire({ icon, title });
  }
}
