import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { AuthService } from '@app/@core/services/auth/auth.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  private token: string | null = null;

  constructor(private _auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf('assets') > -1 ||
      request.url.indexOf('index.php?option') > -1 ||
      request.url.indexOf('k8s-ms-kushkiin') > -1 ||
      request.url.indexOf('api-payment-qa.pervolare.net') > -1
    )
      return next.handle(request);

    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({ url: environment.serverUrl + request.url });
    }

    if (request.url.indexOf('api-blog') > -1) {
      request = request.clone({
        setHeaders: {
          'x-tenant-id': 'tas',
        },
      });
      return next.handle(request);
    }

    let keyApi = environment.apikeys.general;
    this.setToken();

    if (request.url.indexOf(`${environment.services.root}/payment`) > -1) {
      keyApi = environment.apikeys.payments;
    }

    if (request.url.indexOf('v1/bankList') > -1) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Public-Merchant-Id': `${environment.kushki.merchantIdTransfer}`,
        },
      });
      return next.handle(request);
    }

    if (request.url.indexOf(`${environment.services.root}/discountcode`) > -1) {
      keyApi = environment.apikeys.general;
    }

    if (request.url.indexOf('Documents/HelpDocuments') > -1) {
      if (environment.production) {
        request = request.clone({
          setHeaders: {
            'Ocp-Apim-Trace': 'true',
            'key-api-prod': keyApi,
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            'Ocp-Apim-Trace': 'true',
            'key-api': keyApi,
          },
        });
      }
    } else {
      if (environment.production) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Trace': 'true',
            'key-api-prod': keyApi,
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Trace': 'true',
            'key-api': keyApi,
          },
        });
      }
    }

    if (request.url.indexOf('token') > -1) return next.handle(request);
    else {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${this.token}`,
        },
      });
    }

    return next.handle(request);
  }

  setToken() {
    if (this._auth.hasToken()) {
      this.token = this._auth.getToken();
    }
  }
}
