<div class="container">
  <div class="row justify-content-center mt-5">
    <div class="col-12 col-md-8 text-center">
      <span>
        <img
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1706549574/TAS/third-step-quote/icono-error_glq4vt.svg"
          alt="Pago rechazado"
          title="Pago rechazado"
          width="90px"
          height="90px"
        />
      </span>
    </div>
  </div>

  <div class="row justify-content-center mt-3">
    <div class="col-12 col-md-8 text-center mt-3">
      <span class="text-dark font-size-40 fw-bolder"> Pago No Exitoso </span>
    </div>
    <div class="col-12 col-md-8 text-center mt-2">
      <span class="text-danger font-size-30 fw-bolder"> ¡Algo salió mal! </span>
    </div>
    <div class="col-12 col-md-8 text-center">
      <span class="font-color-gray font-size-24">
        Gracias por confiar en nosotros. Intentamos realizar el pago con el método que seleccionaste pero
        lamentablemente fue rechazado.
      </span>
    </div>
  </div>

  <div class="row justify-content-center mt-5">
    <div class="col-12 col-md-8 text-center">
      <span class="text-dark font-size-30 fw-bolder"> ¡No hay de qué preocuparse! </span>
    </div>
    <div class="col-12 col-md-8 text-center">
      <span class="font-color-gray font-size-24"> Tenemos las siguientes soluciones para ti: </span>
    </div>
  </div>

  <div class="row justify-content-center rw-tips mt-5">
    <div class="col-12 col-md-8">
      <span class="font-size-24 font-color-gray">
        <p>
          1. Revisa los datos correctos de tu tarjeta.<br />
          2. Asegúrate de contar con los fondos suficientes o intenta con nuestros distintos medios de pago:
          <b>PayPal, Stripe, Mastercard, American Express, PSE, VISA.</b>
        </p>
      </span>
    </div>
  </div>

  <div class="row justify-content-center mt-5 mb-5">
    <div class="col-12 font-size-25 fw-bolder font-color-gray text-center">
      <span> Si el problema persiste: </span>
    </div>
    <div class="col-12 col-md-6 text-xs-center" align="right">
      <a
        class="btn w-85 my-1 btn-outline-red border border-danger font-size-13 bolder btn-red mt-3"
        routerLink="/cotizar/pago/{{ this.hashCode }}"
        rel="noopener noreferrer"
      >
        Intenta de nuevo tu compra aquí
      </a>
    </div>
    <div class="col-12 col-md-6 text-xs-center" align="left">
      <a
        class="btn w-85 my-1 btn-outline-red border border-white font-size-13 bolder btn-white mt-3"
        href="https://api.whatsapp.com/send?phone=+19282208713&text=Hola,%20necesito%20solicitar%20una%20asistencia%20o%20reportar%20un%20siniestro"
        rel="noopener noreferrer"
      >
        Comunícate por WhatsApp para solicitar ayuda
      </a>
    </div>
  </div>
</div>
