/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GeolocationDto } from '../models/geolocation-dto';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiServicesAppGeolocationGeolocationPost
   */
  static readonly ApiServicesAppGeolocationGeolocationPostPath = '/geo/geolocalization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppGeolocationGeolocationPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppGeolocationGeolocationPost$Plain$Response(params: {
    Authentication: string;
  }): Observable<StrictHttpResponse<GeolocationDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      GeolocationService.ApiServicesAppGeolocationGeolocationPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GeolocationDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppGeolocationGeolocationPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppGeolocationGeolocationPost$Plain(params: { Authentication: string }): Observable<GeolocationDto> {
    return this.apiServicesAppGeolocationGeolocationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GeolocationDto>) => r.body as GeolocationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppGeolocationGeolocationPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppGeolocationGeolocationPost$Json$Response(params: {
    Authentication: string;
  }): Observable<StrictHttpResponse<GeolocationDto>> {
    const rb = new RequestBuilder(this.rootUrl, GeolocationService.ApiServicesAppGeolocationGeolocationPostPath, 'get');
    if (params) {
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GeolocationDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppGeolocationGeolocationPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppGeolocationGeolocationPost$Json(params: { Authentication: string }): Observable<GeolocationDto> {
    return this.apiServicesAppGeolocationGeolocationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GeolocationDto>) => r.body as GeolocationDto)
    );
  }
}
