<div class="container-fluid cont-ourservices">
  <div class="row pb-5">
    <div class="col-12">
      <div class="container pt-5">
        <div class="text-center pb-3">
          <h2><span class="title-blue font-size-32"> Servicios para un viaje inolvidable</span></h2>
        </div>
        <p class="info-service">
          Ofrecemos <a href="/planes" target="_blank" class="text-black">diversos planes</a> que cuentan con cobertura
          de todo tipo todo pensado en tu tranquilidad en más de 5.000 destinos:
          <b>médico, jurídico, en viaje, tecnológico y mucho más.</b>
        </p>
      </div>
    </div>
    <div class="col-12 cont-carousel">
      <div class="carousel">
        <p-carousel
          id="our-services"
          [value]="services"
          [numVisible]="4"
          [numScroll]="1"
          [circular]="true"
          [responsiveOptions]="responsiveOptions"
          [autoplayInterval]="5000"
          [showIndicators]="false"
        >
          <ng-template let-service pTemplate="item">
            <div class="col-md-12 justify-content-center d-flex mt-4 mb-4 card-cont">
              <div class="card pb-3 text-center">
                <img class="img-card" [src]="service.img" alt="Banner service" />
                <div class="content-card px-2 pt-2 d-flex align-items-center">
                  <div class="col-12 text-center px-2">
                    <h3>
                      <b>{{ service.title }}</b>
                    </h3>
                    <p style="text-align: start">
                      {{ service.content }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>
</div>
