import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatmbComponent } from './chatmb.component';

@NgModule({
  declarations: [ChatmbComponent],
  imports: [CommonModule],
  exports: [ChatmbComponent],
})
export class ChatmbModule {}
