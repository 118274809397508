/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StripeRequestDto } from '../models/stripe-request-dto';

@Injectable({
  providedIn: 'root',
})
export class StripePaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiServicesAppStripePaymentConfirmPaymentPost
   */
  static readonly ApiServicesAppStripePaymentConfirmPaymentPostPath = '/payment/Stripe/ConfirmPayment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppStripePaymentConfirmPaymentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppStripePaymentConfirmPaymentPost$Plain$Response(params: {
    Authentication: string;
    body?: StripeRequestDto;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      StripePaymentService.ApiServicesAppStripePaymentConfirmPaymentPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppStripePaymentConfirmPaymentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppStripePaymentConfirmPaymentPost$Plain(params: {
    Authentication: string;
    body?: StripeRequestDto;
  }): Observable<boolean> {
    return this.apiServicesAppStripePaymentConfirmPaymentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppStripePaymentConfirmPaymentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppStripePaymentConfirmPaymentPost$Json$Response(params: {
    Authentication: string;
    body?: StripeRequestDto;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      StripePaymentService.ApiServicesAppStripePaymentConfirmPaymentPostPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppStripePaymentConfirmPaymentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppStripePaymentConfirmPaymentPost$Json(params: {
    Authentication: string;
    body?: StripeRequestDto;
  }): Observable<boolean> {
    return this.apiServicesAppStripePaymentConfirmPaymentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }
}
