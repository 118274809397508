import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { VisaDto } from '@core/api/models';
import { CountryFilterPipe } from './pipe/country-filter.pipe';

@Component({
  selector: 'country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
})
export class CountrySelectComponent {
  @Input() id!: string;
  @Input() position: 'left' | 'right';
  @Input() countries!: VisaDto[];
  @Input() country!: AbstractControl;
  countryFilterForm: FormGroup;

  img_vis =
    'https://us.123rf.com/450wm/aldorado10/aldorado101209/aldorado10120900035/15161216-bot%C3%B3n-de-la-bandera-americana-isoalted-en-blanco.jpg?ver=6';

  get currentCountry(): VisaDto {
    if (this.id == 'intl') {
      return this.countries?.find((country) => country.AlphaCode === this.country.value) ?? this.countries[0];
    } else {
      return this.countries?.find((country) => country.Id === this.country.value) ?? this.countries[0];
    }
  }

  get searchPipe(): string {
    return this.countryFilterForm.value.searchText;
  }

  constructor(private fb: FormBuilder) {
    this.position = 'right';
    this.countryFilterForm = this.fb.group({
      searchText: [''], // El control para el campo de búsqueda
    });
  }

  onSelect(country: number) {
    this.country.setValue(country);
    this.country.markAsDirty();
  }

  onSelectIntl(AlphaCode: string) {
    this.country.setValue(AlphaCode);
    this.country.markAsDirty();
  }
}
