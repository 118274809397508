<div [class]="id == 'intl' ? 'content intl' : 'content'" ngbDropdown display="dynamic" placement="bottom-left">
  <button
    id="select-country-{{ id }}"
    ngbDropdownToggle
    type="button"
    class="w-100 p-0 m-0 btn text-primary d-flex align-items-center justify-content-between"
  >
    <ng-container *ngIf="currentCountry; else loadercurrentCountry">
      <span class="font-size-14 text-black">
        <img
          style="vertical-align: sub"
          loading="lazy"
          *ngIf="currentCountry?.Code"
          width="15px"
          height="15px"
          src="assets/img/country/{{ currentCountry.Code }}.svg"
          [alt]="'Bandera de ' + currentCountry.Name"
          [title]="'Bandera de ' + currentCountry.Name"
        />{{ id == 'intl' ? ' +' + currentCountry.WP : ' ' + currentCountry.Name }}</span
      >
      <i *ngIf="id != 'intl'" class="fas fa-chevron-down font-size-16" aria-hidden="true"></i>
    </ng-container>

    <ng-template #loadercurrentCountry>
      <div class="spinner-grow text-primary ms-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </ng-template>
  </button>

  <!--<span class="font-size-12 text-primary p-0 position-absolute mt-n2">
    <img
      loading="lazy"
      *ngIf="currentCountry?.Code"
      width="15px"
      height="15px"
      src="assets/img/country/{{ currentCountry.Code }}.svg"
      [alt]="currentCountry.Name"
      [title]="currentCountry.Name"
    />
    {{ currentCountry.Name }}</span
  >-->

  <div class="dropdown-menu-country {{ position }}" ngbDropdownMenu [attr.aria-labelledby]="'select-country-' + id">
    <form [formGroup]="countryFilterForm">
      <input type="text" class="form-control search-country" formControlName="searchText" placeholder="Buscar" />
    </form>
    <button
      [ngClass]="{ 'bg-primary text-light': item.Id == currentCountry.Id }"
      type="button"
      class="bolder"
      *ngFor="let item of countries | countryFilter : searchPipe"
      ngbDropdownItem
      (click)="id == 'intl' ? onSelectIntl(item?.AlphaCode || 'US') : onSelect(item?.Id || 1)"
    >
      <img
        loading="lazy"
        width="20px"
        height="20px"
        src="assets/img/country/{{ item?.Code }}.svg"
        [alt]="'Bandera de ' + item?.Name"
        [title]="'Bandera de ' + item?.Name"
      />
      {{ id == 'intl' ? ' +' + item.WP : item?.Name }}
    </button>
  </div>
</div>
