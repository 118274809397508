import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchCertificateDto, SearchCertificateInput } from '@app/@core/api/models/search-certificate-input';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchCertificateService {
  constructor(private http: HttpClient) {}

  getCertificateByNumber(body: SearchCertificateInput): Observable<SearchCertificateDto[]> {
    return this.http.post<SearchCertificateDto[]>(`${environment.serverUrl}/admin/Adm/CertificateConsultation`, body);
  }
}
