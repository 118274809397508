<div
  class="cont-footer"
  *ngIf="showFooter"
  [ngStyle]="currentRoute === '/cotizador-de-proveedores' ? { 'background-color': '#00182F' } : {}"
>
  <div class="container pt-5 pb-3" *ngIf="currentRoute !== '/cotizador-de-proveedores'">
    <div class="row">
      <div class="col-12 col-xl-3 logo-footer mb-3">
        <div class="row">
          <div class="col-12 mb-3"><h5>Nuestros medios de pago</h5></div>
          <div class="row cont-payments">
            <div class="col-3 col-xl-4 mb-2 px-1" *ngFor="let payments of paymentsFooter">
              <img class="img-payment" [src]="payments.value" width="100%" alt="img metodo pago" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-3 info-footer mb-3">
        <h6>
          <b>{{ infoFooter?.TitleAddress }}</b>
        </h6>
        <span *ngFor="let address of addressFooter">{{ address.value }}<br /></span>
        <span class="d-block mt-3 mb-3">
          <b>{{ infoFooter?.TitlePhoneSales }}:</b> {{ infoFooter?.PhoneSales }}<br />
          <b>{{ infoFooter?.TitleEmail }}:</b> {{ infoFooter?.Email }}
        </span>
        <button class="btn-red-header">
          <a [href]="infoFooter?.BtnEmergency" target="_blank">Reportar emergencia</a>
        </button>
      </div>
      <div class="col-12 col-md-6 col-xl-2 info-footer mb-3">
        <h6><b>Legal</b></h6>
        <p class="d-block mt-3">
          <a target="_blank" class="mt-2 d-block" routerLink="/politicas-de-privacidad"
            ><u>Política de privacidad</u></a
          >
          <a target="_blank" class="mt-2 d-block" routerLink="/insurance-documents/webPageConditioned"
            ><u>T&C Condicionado</u></a
          >
          <a
            target="_blank"
            class="mt-2 d-block"
            routerLink="/servicios/terminos-condiciones-promocionales-seguros-viaje"
            ><u>T&C Promocionales</u></a
          >
          <a routerLink="/reclamos" class="mt-2 d-block" *ngIf="topLevelDomain === 'pe'">
            <img
              src="https://res.cloudinary.com/pervolare-org/image/upload/v1674184957/TAS/side-bar/claims_k3bv2d.png"
              width="120px"
              alt="img reclamos"
            />
          </a>
        </p>
      </div>
      <div class="col-12 col-md-6 col-xl-2 info-footer mb-3">
        <h6><b>Planes y Ayuda</b></h6>
        <p class="d-block mt-3">
          <a target="_blank" class="mt-2 d-block" routerLink="/planes"><u>Planes de asistencia</u></a>
          <a target="_blank" class="mt-2 d-block" routerLink="/consulta"><u>Consultar certificado</u></a>
          <a target="_blank" class="mt-2 d-block" routerLink="/preguntas-frecuentes"><u>Preguntas frecuentes</u></a>
        </p>
        <!---->
      </div>
      <div class="col-12 col-md-6 col-xl-2 info-footer mb-3 pe-0">
        <h6><b>Conócenos</b></h6>
        <p class="d-block mt-3">
          <a target="_blank" class="mt-2 d-block" routerLink="/nosotros/por-que"><u>Nosotros</u></a>
          <a target="_blank" class="mt-2 d-block" href="{{ urlBlog }}" target="_blank"><u>Blog</u></a>
          <a target="_blank" class="mt-2 d-block" routerLink="/contacto"><u>Contacto</u></a>
          <button class="btn-whatsapp-footer mt-2">
            <img
              src="https://res.cloudinary.com/pervolare-org/image/upload/v1705335919/TAS/side-bar/wsp_mu5y4j.svg"
              width="22px"
              alt="img whatsapp"
            />
            <a [href]="infoFooter?.BtnWhatsAppSales" target="_blank">Whatsapp de ventas</a>
          </button>
        </p>
      </div>
    </div>
  </div>
  <div class="container" style="padding: 30px 0px" *ngIf="currentRoute === '/cotizador-de-proveedores'">
    <div class="row">
      <div class="col-12 col-xl-3">
        <img
          class="ps-4 ps-lg-0"
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1698435900/TAS/home/logo-tas-white_et6rf0.svg"
          alt="logo traveler assistance services"
        />
      </div>
      <div class="col-12 col-xl-2 d-flex align-items-center justify-content-lg-center">
        <a class="text-white ps-4 ps-lg-0 mb-3 mb-lg-0" target="_blank" routerLink="/politicas-de-privacidad"
          >Política de privacidad</a
        >
      </div>
      <div class="col-12 col-xl-2 d-flex align-items-center justify-content-lg-center">
        <a class="text-white ps-4 ps-lg-0 mb-3 mb-lg-0" target="_blank" routerLink="/condiciones-de-uso"
          >T&C Condicionado</a
        >
      </div>
      <div class="col-12 col-xl-2 d-flex align-items-center justify-content-lg-center">
        <a class="text-white ps-4 ps-lg-0 mb-3 mb-lg-0" target="_blank" routerLink="/nosotros/por-que">Nosotros</a>
      </div>
      <div class="col-12 col-xl-2 d-flex align-items-center justify-content-lg-center">
        <a class="text-white ps-4 ps-lg-0 mb-3 mb-lg-0" target="_blank" routerLink="/contacto">Contacto</a>
      </div>
    </div>
  </div>
</div>
<div class="cont-footer-skyblue">
  <div class="container pt-3 pb-3">
    <div class="row">
      <div class="col-12 col-md-8 d-flex align-items-center">
        <img
          [src]="infoFooterBottom?.flagDomain"
          class="me-2"
          width="25"
          (click)="openFullscreen()"
          alt="bandera dominio"
        />
        {{ infoFooterBottom?.name }} | {{ actualYear }} Traveler Assistance Services. Todos los derechos reservados.
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-center">
        <a href="https://www.facebook.com/TravelerAssistance/" aria-label="redirección a facebook" target="_blank"
          ><img
            src="https://res.cloudinary.com/pervolare-org/image/upload/v1699027510/TAS/footer/Group_2216_edjlop.svg"
            class="img-redes"
            alt="icon facebook"
        /></a>
        <a href="https://www.instagram.com/travelerassistance/" aria-label="redirección a instagram" target="_blank"
          ><img
            src="https://res.cloudinary.com/pervolare-org/image/upload/v1699027509/TAS/footer/INSTAGRAM_1_sm2uzv.svg"
            class="img-redes"
            alt="icon instagram"
        /></a>
        <a
          href="https://www.linkedin.com/company/travelerassistanceservices"
          aria-label="redirección a linkedin"
          target="_blank"
          ><img
            src="https://res.cloudinary.com/pervolare-org/image/upload/v1699027508/TAS/footer/LINKEDING_lg06jz.svg"
            class="img-redes"
            alt="icon linkedin"
        /></a>
        <a href="https://www.tiktok.com/@travelerasssistance" aria-label="redirección a tiktok" target="_blank"
          ><img
            src="https://res.cloudinary.com/pervolare-org/image/upload/v1699027506/TAS/footer/TIKTOK_issjiu.svg"
            class="img-redes"
            alt="icon tiktok"
        /></a>
        <a
          href="https://www.youtube.com/@travelerassistanceservices5444"
          aria-label="redirección a youtube"
          target="_blank"
          ><img
            src="https://res.cloudinary.com/pervolare-org/image/upload/v1699027505/TAS/footer/YOUTUBE_tvox3a.svg"
            class="img-redes"
            alt="icon youtube"
        /></a>
      </div>
    </div>
  </div>
</div>
<div class="modal-fullscreen" [style]="modalactivo ? 'display:block' : 'display:none'">
  <div class="modal-dialog modal-dialog-fullscreen">
    <div class="modal-content">
      <div class="modal-body domains p-6">
        <button type="button" class="btn-close close-domains" aria-label="Close" (click)="modalactivo = false">
          <i class="fas fa-times"></i>
        </button>
        <div class="container d-flex justify-content-center">
          <div class="row align-items-center list-domains">
            <div class="col-3" *ngFor="let domain of domains">
              <a [href]="domain.domain" [title]="domain.name"
                ><i class="fas fa-map-marker-alt" style="color: #004686"></i> {{ domain.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-scrollTop [threshold]="200"></p-scrollTop>
