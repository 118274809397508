<div class="cont-plans">
  <div class="py-5 pb-5">
    <div class="row pb-4 m-0">
      <div class="col-12">
        <div class="container">
          <div class="text-center pb-3">
            <h2><span class="title-white font-size-32"> Nuestros Planes de Asistencia de Viaje</span></h2>
          </div>
          <p class="info-plans">
            Contamos con una variedad de planes hechos especialmente para ti. Podrás encontrar el plan perfecto si eres
            estudiante, adulto mayor, empresario, si planeas un viaje de corta o larga estadía ¡Estamos hechos para
            todos!
          </p>
        </div>
      </div>

      <div class="col-12 cont-carousel" *ngIf="this.plans.length > 0">
        <div class="carousel">
          <p-carousel
            id="info-plans"
            [value]="plans"
            [numVisible]="4"
            [numScroll]="1"
            [circular]="true"
            [responsiveOptions]="responsiveOptions"
            [showNavigators]="true"
            [autoplayInterval]="5000"
            [showIndicators]="false"
          >
            <ng-template let-service pTemplate="item">
              <div class="header-plan" *ngIf="service.index == 0">
                <img
                  src="https://res.cloudinary.com/pervolare-org/image/upload/v1705512024/TAS/home/dv31aqnnaj7kxpio9vxd.svg"
                  alt="icon"
                />
                Recomendado
              </div>
              <div
                [class]="
                  service.index == 0
                    ? 'col-md-12 justify-content-center d-flex mb-4 card-plans-cont fire'
                    : 'col-md-12 justify-content-center d-flex mb-4 mt-5 card-plans-cont'
                "
              >
                <div class="card-plans pb-3 text-center">
                  <img class="img-card" [src]="service.ImageUrl" alt="Banner plan" />
                  <div class="content-card-plans px-2 pt-2 d-flex align-items-center">
                    <div class="col-12 text-center px-2">
                      <h2>
                        <b>{{ service.Name }}</b>
                      </h2>
                      <h3>
                        Cobertura hasta <b>{{ service.Coverage | number }} USD/EUR</b>
                      </h3>
                      <p><b>¿Qué incluye?</b></p>
                      <p *ngFor="let data of service.PlanSummary.slice(0, 5)" class="mb-2">
                        <img
                          src="https://res.cloudinary.com/pervolare-org/image/upload/v1699039335/TAS/home/Check_Circle_zbefgi.svg"
                          width="18px"
                          alt="icon"
                        />
                        {{ data.Benfit }}
                      </p>
                    </div>
                  </div>
                  <div class="btn-seemore">
                    <button
                      class="btn-card mt-3"
                      [routerLink]="['/planes', service.Name?.toLowerCase()?.trim()?.replace(' ', '-')]"
                    >
                      Saber más
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
