import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'plans-dialog-quote',
  template: `<app-quote (reQuote)="closeModal()"></app-quote>`,
  styleUrls: [],
})
export class DialogQuoteComponent {
  constructor(public activeModal: NgbActiveModal) {}

  closeModal(): void {
    this.activeModal.close(true);
  }
}
