import { Deserializable } from './deserializable.model';
import { AbstractControl, FormArray, FormControl, FormGroup, MinValidator, Validators } from '@angular/forms';
import { emailPattern } from '@core/utils/utils';
import { environment } from '@env/environment';

export type ControlNameQuoteModel =
  | 'origin'
  | 'destiny'
  | 'departureDate'
  | 'arrivalDate'
  | 'email'
  | 'discountCode'
  | 'userId'
  | 'intl'
  | 'phone'
  | 'ages'
  | 'planId'
  | 'firstName'
  | 'lastName'
  | 'wantContacted';

export class QuoteModel implements Deserializable {
  private form: FormGroup;

  public origin: number = 1;
  public planId: number = 0;
  public destiny: number = 3;
  public departureDate: string = '';
  public arrivalDate: string = '';
  public email: string = '';
  public discountCode: string = '';
  public intl: string = '';
  public phone: string = '';
  public userId: number = environment.userB2C;
  public ages: number[] = [];
  //public firstName: string = '';
  //public lastName: string = '';

  get agesControl(): FormArray {
    return this.getControl('ages') as FormArray;
  }

  constructor() {
    this.form = new FormGroup({});
  }

  /**
   * Retorna una nueva referencia del quoteForm
   * @returns FormGroup
   */
  createForm(): FormGroup {
    this.form = new FormGroup({
      origin: new FormControl(this.origin, Validators.required),
      destiny: new FormControl(this.destiny, Validators.required),
      departureDate: new FormControl(this.departureDate, Validators.required),
      arrivalDate: new FormControl(this.arrivalDate, Validators.required),
      email: new FormControl(this.email, [Validators.required, Validators.pattern(emailPattern)]),
      discountCode: new FormControl(this.discountCode),
      userId: new FormControl(this.userId),
      intl: new FormControl(this.intl),
      phone: new FormControl(this.phone, [Validators.required, Validators.minLength(6), Validators.min(0)]),
      ages: new FormArray([new FormControl(null, Validators.required)]),
      planId: new FormControl(),
      //firstName: new FormControl(this.firstName, [Validators.required, Validators.minLength(3)]),
      //lastName: new FormControl(this.lastName, [Validators.required, Validators.minLength(3)]),
    });

    // this.form.valueChanges.subscribe(console.log);
    return this.form;
  }

  private prepareSave(): QuoteModel {
    return new QuoteModel().deserialize(this.form.value);
  }

  getControl(name: ControlNameQuoteModel): AbstractControl | null {
    return this.form.get(name);
  }

  addAge() {
    this.agesControl.push(new FormControl(null, Validators.required));
  }

  removeAge(index: number) {
    this.agesControl.removeAt(index);
  }

  onSubmit() {
    return this.prepareSave();
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
