import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from '../../models/base/base-response.dto';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { PayuRequestDto } from '../../models/payments/payu-request.dto';
import { PayuResponseDto } from '../../models/payments/payu-response.dto';

@Injectable({
  providedIn: 'root',
})
export class PayuPaymentService {
  constructor(private http: HttpClient) {}

  public createPayment(input: PayuRequestDto): Observable<PayuResponseDto> {
    return this.http
      .post<BaseResponse<PayuResponseDto>>(`${environment.serverUrl}/payment/payu/createPayment`, input)
      .pipe(map((p) => p.result));
  }
}
