import { style } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@shared';
import { format } from 'date-fns';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'quote-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input() public onInit!: EventEmitter<{ inCheckout: boolean }>;
  @Input() public arrivalDate!: AbstractControl | null;
  @Input() public departureDate!: AbstractControl | null;
  @ViewChild('datepicker') public datepicker!: NgbInputDatepicker;

  public now: Date;
  public toDate: NgbDate | null;
  public fromDate: NgbDate | null;
  public hoveredDate: NgbDate | null;
  public travelDates: { [key: string]: { year: number; month: number; day: number } };

  get toDateFormat(): string {
    return this.toDate
      ? format(new Date(this.toDate?.year ?? 0, (this.toDate?.month ?? 1) - 1, this.toDate?.day ?? 0), 'dd-M-yyyy')
      : '';
  }

  get fromDateFormat(): string {
    return this.fromDate
      ? format(
          new Date(this.fromDate?.year ?? 0, (this.fromDate?.month ?? 1) - 1, this.fromDate?.day ?? 0),
          'dd-M-yyyy'
        )
      : '';
  }

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.now = new Date();
    this.toDate = null;
    this.fromDate = null;
    this.hoveredDate = null;
    this.travelDates = {
      min: calendar.getToday(),
      max: calendar.getNext(calendar.getToday(), 'y', 3),
    };
  }

  ngOnInit(): void {
    this.onInit
      .pipe(
        map((value) => value.inCheckout),
        untilDestroyed(this)
      )
      .subscribe((inCheckout) => {
        // Set Travel Dates

        if (inCheckout) {
          const fromDate = this.calendar.getToday();
          const departureDate = this.formatter.parse(this.departureDate?.value);
          this.fromDate = new NgbDate(
            departureDate?.year ?? fromDate.year,
            departureDate?.month ?? fromDate.month,
            departureDate?.day ?? fromDate.day
          );

          const toDate = this.getDefaultToDate();
          const arrivalDate = this.formatter.parse(this.arrivalDate?.value);
          this.toDate = new NgbDate(
            arrivalDate?.year ?? toDate.year,
            arrivalDate?.month ?? toDate.month,
            arrivalDate?.day ?? toDate.day
          );
        } else {
          // this.departureDate?.setValue(this.formatter.format(this.fromDate));
          // this.arrivalDate?.setValue(this.formatter.format(this.toDate));
          this.fromDate = null;
          this.toDate = null;
        }
      });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.arrivalDate?.setValue(this.formatter.format(this.toDate));
      this.arrivalDate?.markAsDirty();
      this.datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.arrivalDate?.setValue(null);
      this.arrivalDate?.markAsDirty();
    }

    // Establece un mínimo de 3 días de viaje
    // TODO: Refactizar
    if (
      this.fromDate &&
      this.toDate &&
      this.fromDate.year == this.toDate.year &&
      this.fromDate.month == this.toDate.month &&
      this.toDate.day < this.fromDate.day + 2
    ) {
      this.toDate = this.calendar.getNext(this.fromDate, 'd', 2);
      this.arrivalDate?.setValue(this.formatter.format(this.toDate));
      this.arrivalDate?.markAsDirty();
    }

    this.departureDate?.setValue(this.formatter.format(this.fromDate));
    this.departureDate?.markAsDirty();
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  private getDefaultToDate(): NgbDate {
    return this.calendar.getNext(this.calendar.getToday(), 'd', 3);
  }
}
