<!--<button  (click)="datepicker.toggle()" type="button" class="btn p-0">
  <i class="far fa-calendar-alt font-size-28 text-primary"></i>
  <p class="h4 font-size-12 text-primary">Fecha de viaje</p>
</button>-->
<div class="form-group hidden">
  <div id="datepicker-quote" class="input-group">
    <input
      id="fechas"
      class="form-control"
      type="hidden"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      [autoClose]="'outside'"
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="1"
      outsideDays="hidden"
      [startDate]="fromDate!"
      [minDate]="travelDates.min"
      [maxDate]="travelDates.max"
      [dayTemplate]="dt"
      [positionTarget]="buttonEl"
      tabindex="-1"
    />
    <ng-template #dt let-date let-focused="focused">
      <span
        class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
    </ng-template>
    <ng-template ngbDatepickerContent> </ng-template>
  </div>
</div>
<div #buttonEl (click)="datepicker.toggle()" style="cursor: pointer" class="row header-form mb-2">
  <!--<span *ngIf="!toDate" id="departureDate">{{ fromDateFormat }}</span>
  <span *ngIf="toDate" style="color: #004686" id="departureDate">{{ fromDateFormat }}</span>
  <br />
  <span *ngIf="!fromDate" id="arrivalDate">{{ toDateFormat }}</span>
  <div *ngIf="fromDate">
    <span style="color: #004686" id="arrivalDate">{{ toDateFormat }}</span>
  </div>-->

  <div class="col-6 px-2">
    <p class="h4 font-size-12 text-primary mt-1">FECHA DE SALIDA</p>
    <input
      #departureDateEl
      type="text"
      class="form-control travel-date"
      readonly
      [value]="fromDateFormat"
      placeholder="dd-mm-yyyy"
    />
  </div>
  <div class="col-6 px-2">
    <p class="h4 font-size-12 text-primary mt-1">FECHA DE RETORNO</p>
    <input
      #departureDateEl
      type="text"
      class="form-control travel-date"
      readonly
      [value]="toDateFormat"
      placeholder="dd-mm-yyyy"
    />
  </div>
</div>
