/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../../base-service';
import { ApiConfiguration } from '../../api-configuration';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MercadoPagoRequestDto } from '../../models/payments/mercado-pago-request.dto';
import { environment } from '@env/environment';
import { MercadoPagoResponseDto } from '../../models/payments/mercado-pago-response.dto';
import { BaseResponse } from '../../models/base/base-response.dto';

@Injectable({
  providedIn: 'root',
})
export class MercadoPagoPaymentService {
  constructor(private http: HttpClient) {}

  public createPayment(input: MercadoPagoRequestDto): Observable<MercadoPagoResponseDto> {
    return this.http
      .post<BaseResponse<MercadoPagoResponseDto>>(`${environment.serverUrl}/payment/mercadoPago/preference`, input)
      .pipe(map((p) => p.result));
  }
}
