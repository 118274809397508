import { GeolocationDto } from '@app/@core/api/models';

export const DEFAULT_LOCATION: GeolocationDto = {
  Visas: [
    {
      Alias: 'United States',
      Code: 'USA',
      CurrencyCode: 'usd',
      Id: 1,
      IsNationalCurrency: false,
      Name: 'Estados Unidos',
      WP: '1',
    },
    {
      Alias: 'Brazil',
      Code: 'BRA',
      CurrencyCode: 'brl',
      Id: 22,
      IsNationalCurrency: false,
      Name: 'Brasil',
      WP: '55',
    },
    {
      Alias: 'Guatemala',
      Code: 'GTM',
      CurrencyCode: 'gtq',
      Id: 51,
      IsNationalCurrency: false,
      Name: 'Guatemala',
      WP: '502',
    },
    {
      Alias: 'Honduras',
      Code: 'HND',
      CurrencyCode: 'hnl',
      Id: 56,
      IsNationalCurrency: false,
      Name: 'Honduras',
      WP: '504',
    },
    {
      Alias: 'Belize',
      Code: 'BLZ',
      CurrencyCode: 'bzd',
      Id: 18,
      IsNationalCurrency: false,
      Name: 'Belice',
      WP: '501',
    },
    {
      Alias: 'Colombia',
      Code: 'COL',
      CurrencyCode: 'cop',
      Id: 14,
      IsNationalCurrency: true,
      Name: 'Colombia',
      WP: '57',
    },
    {
      Alias: 'Argentina',
      Code: 'ARG',
      CurrencyCode: 'ars',
      Id: 11,
      IsNationalCurrency: true,
      Name: 'Argentina',
      WP: '54',
    },
    {
      Alias: 'Bolivia',
      Code: 'BOL',
      CurrencyCode: 'bob',
      Id: 20,
      IsNationalCurrency: false,
      Name: 'Bolivia',
      WP: '591',
    },
    {
      Alias: 'Chile',
      Code: 'CHL',
      CurrencyCode: 'clp',
      Id: 24,
      IsNationalCurrency: true,
      Name: 'Chile',
      WP: '56',
    },
    {
      Alias: 'Peru',
      Code: 'PER',
      CurrencyCode: 'pen',
      Id: 91,
      IsNationalCurrency: true,
      Name: 'Perú',
      WP: '51',
    },
    {
      Alias: 'Venezuela',
      Code: 'VEN',
      CurrencyCode: 'ves',
      Id: 116,
      IsNationalCurrency: false,
      Name: 'Venezuela',
      WP: '58',
    },
    {
      Alias: 'Costa Rica',
      Code: 'CRI',
      CurrencyCode: 'crc',
      Id: 30,
      IsNationalCurrency: false,
      Name: 'Costa Rica',
      WP: '506',
    },
    {
      Alias: 'Dominican Rep.',
      Code: 'DOM',
      CurrencyCode: 'dop',
      Id: 96,
      IsNationalCurrency: false,
      Name: 'Rep. Dominicana',
      WP: '1829',
    },
    {
      Alias: 'Ecuador',
      Code: 'ECU',
      CurrencyCode: 'usd',
      Id: 36,
      IsNationalCurrency: true,
      Name: 'Ecuador',
      WP: '593',
    },
    {
      Alias: 'Mexico',
      Code: 'MEX',
      CurrencyCode: 'mxn',
      Id: 79,
      IsNationalCurrency: true,
      Name: 'México',
      WP: '52',
    },
    {
      Alias: 'Panama',
      Code: 'PAN',
      CurrencyCode: 'pab',
      Id: 89,
      IsNationalCurrency: true,
      Name: 'Panamá',
      WP: '507',
    },
    {
      Alias: 'Nicaragua',
      Code: 'NIC',
      CurrencyCode: 'nio',
      Id: 84,
      IsNationalCurrency: false,
      Name: 'Nicaragua',
      WP: '505',
    },
    {
      Alias: 'Europe',
      Code: 'EUR',
      CurrencyCode: 'eur',
      Id: 3,
      IsNationalCurrency: false,
      Name: 'Europa',
      WP: '0',
    },
    {
      Alias: 'Worldwide',
      Code: 'MUN',
      CurrencyCode: 'usd',
      Id: 81,
      IsNationalCurrency: false,
      Name: 'Mundial',
      WP: '0',
    },
    {
      Alias: 'Worldwide (NO USA)',
      Code: 'MUNN',
      CurrencyCode: 'eur',
      Id: 82,
      IsNationalCurrency: false,
      Name: 'Mundial (NO USA)',
      WP: '0',
    },
    {
      Alias: 'South America',
      Code: 'SUD',
      CurrencyCode: 'eur',
      Id: 9,
      IsNationalCurrency: false,
      Name: 'América del Sur',
      WP: '0',
    },
    {
      Alias: 'Asia',
      Code: 'ASI',
      CurrencyCode: 'eur',
      Id: 13,
      IsNationalCurrency: false,
      Name: 'Asia',
      WP: '0',
    },
  ],
};
