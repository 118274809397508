import { Component, OnInit } from '@angular/core';
import { ParameterByDomainService, StorageService } from '@core/services';
import { Domains, InfoFooter } from '@app/interfaces';
import { GoogleAnaliticsService } from '@app/@core/services/analytics/google-analitics.service';
import imgPaymentsMethods from 'src/assets/data/imgPaymentMethods.json';
import { map } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ParamentersFooterDto } from '@app/@core/api/models';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public infoFooter: ParamentersFooterDto | undefined;
  public infoFooterBottom: InfoFooter | undefined;
  public domains: Domains[] | undefined;
  public modalactivo: boolean = false;
  public imgPaymentsMethods: any = imgPaymentsMethods;
  public paymentMethods: Array<string> | undefined;
  showFooter: boolean = true;
  public paymentsFooter: [{ name: ''; value: '' }] | undefined;
  public addressFooter: [{ name: ''; value: '' }] | undefined;
  public actualYear: number = new Date().getFullYear();
  urlBlog: string;
  public currentRoute: string = '';

  get topLevelDomain(): string {
    return this._storage.getTopLevelDomain();
  }

  constructor(
    private readonly _parameterDomain: ParameterByDomainService,
    private _storage: StorageService,
    private _analytics: GoogleAnaliticsService,
    private router: Router
  ) {
    const topLevelDomain = this._storage.getItem('topLevelDomain');
    const validBlog: string[] = ['co', 'cl', 'pe', 've', 'cr', 'ec', 'mx', 'om'];
    this.urlBlog = validBlog.includes(topLevelDomain!)
      ? 'https://' + window.location.host + '/blog'
      : 'https://traveler-assistance.com/blog';
    this.currentPaymentMethods();
    this.validateUrlstartsWith();

    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
  }

  ngOnInit() {
    this.getInfoFooter();
    this.getInfoFooterDomains();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.validateUrlstartsWith();
      }
    });
  }

  validateUrlstartsWith() {
    var slug = false;
    const notAllowed = ['/cotizar', '/pago'];
    for (const item of notAllowed) {
      slug = this.router.url.startsWith(item);
      if (slug) {
        break;
      }
    }
    this.showFooter = !slug;
  }

  getInfoFooter() {
    this._parameterDomain.getParameterInfoFooter(this._storage.getTopLevelDomain()).subscribe((info) => {
      this.infoFooter = info[0];
      this.paymentsFooter = JSON.parse(info[0]?.PaymentMethods);
      this.addressFooter = JSON.parse(info[0]?.Address);
    });

    this._parameterDomain
      .getInfoFooterByTopLevelDomain(this._storage.getTopLevelDomain())
      .subscribe((infoFooter) => (this.infoFooterBottom = infoFooter));
  }

  getInfoFooterDomains() {
    this._parameterDomain.getInfoFooter().subscribe((infoFooter) => {
      this.domains = infoFooter.map((infoFooter) => ({
        name: infoFooter.name,
        domain: infoFooter.domain,
      }));
    });
  }

  public socialEvent(social: string): void {
    /**Google Analytics Event */
    this._analytics.addEvent('redes', social);
  }

  openFullscreen() {
    this.modalactivo = true;
  }

  public currentPaymentMethods() {
    this.getDataByDomain().subscribe({
      next: (domain) => {
        this.paymentMethods = domain?.paymentMethods;
      },
    });
  }

  private getDataByDomain() {
    const currentDomain = this._storage.getTopLevelDomain();
    return this._parameterDomain
      .getDomains()
      .pipe(map((domains) => domains.find((domain) => domain.topLevelDomain === currentDomain)));
  }
}
