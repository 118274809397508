import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { SidebarModule } from 'ng-sidebar';
import { ContactMenuComponent } from './sidebar-menu/contact-menu/contact-menu.component';
import { AgentMenuComponent } from './sidebar-menu/agent-menu/agent-menu.component';
import { ParticularMenuComponent } from './sidebar-menu/particular-menu/particular-menu.component';
import { ChatModule } from '@modules/chat/chat.module';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ChatmbModule } from '@modules/chatmb/chatmb.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    RouterModule,
    ChatModule,
    SidebarModule.forRoot(),
    ScrollTopModule,
    ChatmbModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarMenuComponent,
    ShellComponent,
    ContactMenuComponent,
    AgentMenuComponent,
    ParticularMenuComponent,
    NavbarMenuComponent,
  ],
})
export class ShellModule {}
