import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteComponent } from './quote.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CountrySelectModule } from '@modules';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { SharedModule } from '@app/@shared';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { PhoneValidatorService } from './services/phone-validator/phone-validator.service';

@NgModule({
  declarations: [QuoteComponent, DatePickerComponent],
  exports: [QuoteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbAccordionModule,
    CountrySelectModule,
    SharedModule,
    ContentLoaderModule,
    NgbDropdownModule,
  ],
  providers: [PhoneValidatorService],
})
export class QuoteModule {}
