import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TravelInsuranceQuoteResponseDto } from '@app/@core/api/models';

@Component({
  selector: 'app-payment-declined',
  templateUrl: './payment-declined.component.html',
  styleUrls: ['./payment-declined.component.scss'],
})
export class PaymentDeclinedComponent implements OnInit {
  public loading: boolean;
  public hashCode: string = '';

  constructor(private route: ActivatedRoute, private router: Router) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.hashCode = params['hashCode'];
    });
  }
}
