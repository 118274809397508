/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import {
  AdminGetOrderStatusRequestDto,
  AdminTravelInsuranceRequestDto,
} from '../models/admin-travel-insurance-request-dto';
import { PaymentEngineType } from '../models/payment-engine-type';
import { TravelInsuranceCustomerDto } from '../models/travel-insurance-customer-dto';
import { TravelInsuranceNewsLetterDto } from '../models/travel-insurance-news-letter-dto';
import { TravelInsuranceRequestDto } from '../models/travel-insurance-request-dto';
import { TravelInsuranceRequestDtoPagedResultDto } from '../models/travel-insurance-request-dto-paged-result-dto';
import { TravelInsuranceRequestQueryTypeEnum } from '../models/travel-insurance-request-query-type-enum';
import { TravelInsuranceRequestReportDto } from '../models/travel-insurance-request-report-dto';
import { TravelInsuranceRequestReportInput } from '../models/travel-insurance-request-report-input';
import { TravelInsuranceRequestUpdateCityDto } from '../models/travel-insurance-request-update-city-dto';
import { UpdateFieldDto } from '../models/update-field-dto';
import { UpdateStatusDto } from '../models/update-status-dto';
import { TravelInsuranceRequestAdminDto } from '../models/travel-insurance-request-admin-dto';
import { TravelInsuranceRequestAdminTasOld } from '../models/travel-insurance-request-admin-tasold';
import { TravelInsuranceCustomerTasOld } from '../models/travel-insurance-customer-tas_old';

@Injectable({
  providedIn: 'root',
})
export class TravelInsuranceRequestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestUpdatePut
   */
  static readonly ApiServicesAppTravelInsuranceRequestUpdatePutPath = '/api/services/app/TravelInsuranceRequest/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestUpdatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestUpdatePut$Plain$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestDto;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestUpdatePutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestUpdatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestUpdatePut$Plain(params: {
    Authentication: string;
    body?: TravelInsuranceRequestDto;
  }): Observable<TravelInsuranceRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestUpdatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceRequestDto>) => r.body as TravelInsuranceRequestDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestUpdatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestUpdatePut$Json$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestDto;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestUpdatePutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestUpdatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestUpdatePut$Json(params: {
    Authentication: string;
    body?: TravelInsuranceRequestDto;
  }): Observable<TravelInsuranceRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestUpdatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceRequestDto>) => r.body as TravelInsuranceRequestDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestUpdateCityPut
   */
  static readonly ApiServicesAppTravelInsuranceRequestUpdateCityPutPath =
    '/api/services/app/TravelInsuranceRequest/UpdateCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestUpdateCityPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestUpdateCityPut$Plain$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestUpdateCityDto;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestUpdateCityPutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestUpdateCityPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestUpdateCityPut$Plain(params: {
    Authentication: string;
    body?: TravelInsuranceRequestUpdateCityDto;
  }): Observable<boolean> {
    return this.apiServicesAppTravelInsuranceRequestUpdateCityPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestUpdateCityPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestUpdateCityPut$Json$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestUpdateCityDto;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestUpdateCityPutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestUpdateCityPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestUpdateCityPut$Json(params: {
    Authentication: string;
    body?: TravelInsuranceRequestUpdateCityDto;
  }): Observable<boolean> {
    return this.apiServicesAppTravelInsuranceRequestUpdateCityPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestGetOrderStatusGet
   */
  static readonly ApiServicesAppTravelInsuranceRequestGetOrderStatusGetPath = '/quote/Adm/GetOrderStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Plain$Response(params: {
    hashCode?: string;
    Authentication: string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetOrderStatusGetPath,
      'post'
    );
    if (params) {
      rb.query('hashCode', params.hashCode, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Plain(params: {
    hashCode?: string;
    Authentication: string;
  }): Observable<string> {
    return this.apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Json$Response(params: {
    hashCode?: string;
    Authentication: string;
  }): Observable<StrictHttpResponse<AdminGetOrderStatusRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetOrderStatusGetPath,
      'post'
    );
    if (params) {
      // rb.query('hashCode', params.hashCode, {});
      // rb.header('Authentication', params.Authentication, {});
      rb.body({ HashCode: params.hashCode }, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AdminGetOrderStatusRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Json(params: {
    hashCode?: string;
    Authentication: string;
  }): Observable<AdminGetOrderStatusRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestGetOrderStatusGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AdminGetOrderStatusRequestDto>) => r.body as AdminGetOrderStatusRequestDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestGetOrderByCodeGet
   */
  static readonly ApiServicesAppTravelInsuranceRequestGetOrderByCodeGetPath =
    '/api/services/app/TravelInsuranceRequest/GetOrderByCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Plain$Response(params: {
    code?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetOrderByCodeGetPath,
      'get'
    );
    if (params) {
      rb.query('code', params.code, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Plain(params: {
    code?: number;
    Authentication: string;
  }): Observable<TravelInsuranceRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceRequestDto>) => r.body as TravelInsuranceRequestDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Json$Response(params: {
    code?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetOrderByCodeGetPath,
      'get'
    );
    if (params) {
      rb.query('code', params.code, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Json(params: {
    code?: number;
    Authentication: string;
  }): Observable<TravelInsuranceRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestGetOrderByCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceRequestDto>) => r.body as TravelInsuranceRequestDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestGetPriceByHashGet
   */
  static readonly ApiServicesAppTravelInsuranceRequestGetPriceByHashGetPath =
    '/api/services/app/TravelInsuranceRequest/GetPriceByHash';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Plain$Response(params: {
    hashCode?: string;
    Authentication: string;
  }): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetPriceByHashGetPath,
      'get'
    );
    if (params) {
      rb.query('hashCode', params.hashCode, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: parseFloat(String((r as HttpResponse<any>).body)),
          }) as StrictHttpResponse<number>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Plain(params: {
    hashCode?: string;
    Authentication: string;
  }): Observable<number> {
    return this.apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Json$Response(params: {
    hashCode?: string;
    Authentication: string;
  }): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetPriceByHashGetPath,
      'get'
    );
    if (params) {
      rb.query('hashCode', params.hashCode, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: parseFloat(String((r as HttpResponse<any>).body)),
          }) as StrictHttpResponse<number>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Json(params: {
    hashCode?: string;
    Authentication: string;
  }): Observable<number> {
    return this.apiServicesAppTravelInsuranceRequestGetPriceByHashGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestToggleNewsLetterPost
   */
  static readonly ApiServicesAppTravelInsuranceRequestToggleNewsLetterPostPath =
    '/api/services/app/TravelInsuranceRequest/ToggleNewsLetter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestToggleNewsLetterPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestToggleNewsLetterPost$Response(params: {
    Authentication: string;
    body?: TravelInsuranceNewsLetterDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestToggleNewsLetterPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestToggleNewsLetterPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestToggleNewsLetterPost(params: {
    Authentication: string;
    body?: TravelInsuranceNewsLetterDto;
  }): Observable<void> {
    return this.apiServicesAppTravelInsuranceRequestToggleNewsLetterPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestGetGet
   */
  static readonly ApiServicesAppTravelInsuranceRequestGetGetPath = '/api/services/app/TravelInsuranceRequest/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetGet$Plain$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetGetPath,
      'get'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetGet$Plain(params: {
    Id?: number;
    Authentication: string;
  }): Observable<TravelInsuranceRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestGetGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceRequestDto>) => r.body as TravelInsuranceRequestDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetGet$Json$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetGetPath,
      'get'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetGet$Json(params: {
    Id?: number;
    Authentication: string;
  }): Observable<TravelInsuranceRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestGetGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceRequestDto>) => r.body as TravelInsuranceRequestDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestGetAllGet
   */
  static readonly ApiServicesAppTravelInsuranceRequestGetAllGetPath = '/api/services/app/TravelInsuranceRequest/GetAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetAllGet$Plain$Response(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDtoPagedResultDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetAllGetPath,
      'get'
    );
    if (params) {
      rb.query('Sorting', params.Sorting, {});
      rb.query('SkipCount', params.SkipCount, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDtoPagedResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetAllGet$Plain(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<TravelInsuranceRequestDtoPagedResultDto> {
    return this.apiServicesAppTravelInsuranceRequestGetAllGet$Plain$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TravelInsuranceRequestDtoPagedResultDto>) =>
          r.body as TravelInsuranceRequestDtoPagedResultDto
      )
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetAllGet$Json$Response(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDtoPagedResultDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetAllGetPath,
      'get'
    );
    if (params) {
      rb.query('Sorting', params.Sorting, {});
      rb.query('SkipCount', params.SkipCount, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDtoPagedResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetAllGet$Json(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<TravelInsuranceRequestDtoPagedResultDto> {
    return this.apiServicesAppTravelInsuranceRequestGetAllGet$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TravelInsuranceRequestDtoPagedResultDto>) =>
          r.body as TravelInsuranceRequestDtoPagedResultDto
      )
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestCreatePost
   */
  static readonly ApiServicesAppTravelInsuranceRequestCreatePostPath =
    '/api/services/app/TravelInsuranceRequest/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestCreatePost$Plain$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestDto;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestCreatePostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestCreatePost$Plain(params: {
    Authentication: string;
    body?: TravelInsuranceRequestDto;
  }): Observable<TravelInsuranceRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceRequestDto>) => r.body as TravelInsuranceRequestDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestCreatePost$Json$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestDto;
  }): Observable<StrictHttpResponse<TravelInsuranceRequestDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestCreatePostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsuranceRequestDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestCreatePost$Json(params: {
    Authentication: string;
    body?: TravelInsuranceRequestDto;
  }): Observable<TravelInsuranceRequestDto> {
    return this.apiServicesAppTravelInsuranceRequestCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsuranceRequestDto>) => r.body as TravelInsuranceRequestDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestDeleteDelete
   */
  static readonly ApiServicesAppTravelInsuranceRequestDeleteDeletePath =
    '/api/services/app/TravelInsuranceRequest/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestDeleteDelete$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestDeleteDeletePath,
      'delete'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestDeleteDelete(params: { Id?: number; Authentication: string }): Observable<void> {
    return this.apiServicesAppTravelInsuranceRequestDeleteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestGetAdminGet
   */
  static readonly ApiServicesAppTravelInsuranceRequestGetAdminGetPath =
    '/api/services/app/TravelInsuranceRequest/GetAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetAdminGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetAdminGet$Plain$Response(params: {
    Query?: string;
    QueryType?: TravelInsuranceRequestQueryTypeEnum;
    PaymentEngineType?: PaymentEngineType;
    Authentication: string;
  }): Observable<StrictHttpResponse<Array<AdminTravelInsuranceRequestDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetAdminGetPath,
      'get'
    );
    if (params) {
      rb.query('Query', params.Query, {});
      rb.query('QueryType', params.QueryType, {});
      rb.query('PaymentEngineType', params.PaymentEngineType, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AdminTravelInsuranceRequestDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetAdminGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetAdminGet$Plain(params: {
    Authentication: string;
  }): Observable<Array<AdminTravelInsuranceRequestDto>> {
    return this.apiServicesAppTravelInsuranceRequestGetAdminGet$Plain$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<AdminTravelInsuranceRequestDto>>) =>
          r.body as Array<AdminTravelInsuranceRequestDto>
      )
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetAdminGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetAdminGet$Json$Response(params: {
    Query?: string;
    QueryType?: TravelInsuranceRequestQueryTypeEnum;
    PaymentEngineType?: PaymentEngineType;
    Authentication: string;
  }): Observable<StrictHttpResponse<Array<AdminTravelInsuranceRequestDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetAdminGetPath,
      'get'
    );
    if (params) {
      rb.query('Query', params.Query, {});
      rb.query('QueryType', params.QueryType, {});
      rb.query('PaymentEngineType', params.PaymentEngineType, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AdminTravelInsuranceRequestDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetAdminGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetAdminGet$Json(params: {
    Query?: string;
    QueryType?: TravelInsuranceRequestQueryTypeEnum;
    PaymentEngineType?: PaymentEngineType;
    Authentication: string;
  }): Observable<Array<AdminTravelInsuranceRequestDto>> {
    return this.apiServicesAppTravelInsuranceRequestGetAdminGet$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<AdminTravelInsuranceRequestDto>>) =>
          r.body as Array<AdminTravelInsuranceRequestDto>
      )
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet
   */
  static readonly ApiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGetPath =
    '/admin/Adm/PurchaseNumber';

  static readonly ApiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByTasOldGetPath =
    '/index.php?option=com_functions&task=sistema_consulta&rutt=rutt&';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Plain$Response(params: {
    Query?: string;
    QueryType?: TravelInsuranceRequestQueryTypeEnum;
    PaymentEngineType?: PaymentEngineType;
    Authentication: string;
  }): Observable<StrictHttpResponse<Array<TravelInsuranceCustomerDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGetPath,
      'get'
    );
    if (params) {
      rb.query('Query', params.Query, {});
      rb.query('QueryType', params.QueryType, {});
      rb.query('PaymentEngineType', params.PaymentEngineType, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TravelInsuranceCustomerDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Plain(params: {
    Query?: string;
    QueryType?: TravelInsuranceRequestQueryTypeEnum;
    PaymentEngineType?: PaymentEngineType;
    Authentication: string;
  }): Observable<Array<TravelInsuranceCustomerDto>> {
    return this.apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Plain$Response(
      params
    ).pipe(
      map((r: StrictHttpResponse<Array<TravelInsuranceCustomerDto>>) => r.body as Array<TravelInsuranceCustomerDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Json$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestAdminDto;
  }): Observable<StrictHttpResponse<Array<TravelInsuranceCustomerDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGetPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TravelInsuranceCustomerDto>>;
        })
      );
  }

  /**
   *
   * Consulta certificados TAS antiguo
   */

  apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGetTasOld$Json$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestAdminTasOld;
  }): Observable<StrictHttpResponse<Array<TravelInsuranceCustomerTasOld>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByTasOldGetPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'multipart/form-data',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TravelInsuranceCustomerTasOld>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Json(params: {
    Authentication: string;
    body?: TravelInsuranceRequestAdminDto;
  }): Observable<Array<TravelInsuranceCustomerDto>> {
    return this.apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGet$Json$Response(
      params
    ).pipe(
      map((r: StrictHttpResponse<Array<TravelInsuranceCustomerDto>>) => r.body as Array<TravelInsuranceCustomerDto>)
    );
  }

  /**
   *
   * Consulta certificados TAS antiguo
   */

  apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGetTasOld$Json(params: {
    Authentication: string;
    body?: TravelInsuranceRequestAdminTasOld;
  }): Observable<Array<TravelInsuranceCustomerTasOld>> {
    return this.apiServicesAppTravelInsuranceRequestGetTravelInsuranceRequestByPurchaseNumberGetTasOld$Json$Response(
      params
    ).pipe(
      map(
        (r: StrictHttpResponse<Array<TravelInsuranceCustomerTasOld>>) => r.body as Array<TravelInsuranceCustomerTasOld>
      )
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestTravelUpdateStatusPost
   */
  static readonly ApiServicesAppTravelInsuranceRequestTravelUpdateStatusPostPath =
    '/api/services/app/TravelInsuranceRequest/TravelUpdateStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestTravelUpdateStatusPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestTravelUpdateStatusPost$Response(params: {
    Authentication: string;
    body?: UpdateStatusDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestTravelUpdateStatusPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestTravelUpdateStatusPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestTravelUpdateStatusPost(params: {
    Authentication: string;
    body?: UpdateStatusDto;
  }): Observable<void> {
    return this.apiServicesAppTravelInsuranceRequestTravelUpdateStatusPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestTravelUpdateAdminPost
   */
  static readonly ApiServicesAppTravelInsuranceRequestTravelUpdateAdminPostPath =
    '/api/services/app/TravelInsuranceRequest/TravelUpdateAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestTravelUpdateAdminPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestTravelUpdateAdminPost$Response(params: {
    Authentication: string;
    body?: UpdateFieldDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestTravelUpdateAdminPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestTravelUpdateAdminPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestTravelUpdateAdminPost(params: {
    Authentication: string;
    body?: UpdateFieldDto;
  }): Observable<void> {
    return this.apiServicesAppTravelInsuranceRequestTravelUpdateAdminPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost
   */
  static readonly ApiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPostPath =
    '/api/services/app/TravelInsuranceRequest/TravelInsuranceRequestlReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Plain$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestReportInput;
  }): Observable<StrictHttpResponse<Array<TravelInsuranceRequestReportDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TravelInsuranceRequestReportDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Plain(params: {
    Authentication: string;
    body?: TravelInsuranceRequestReportInput;
  }): Observable<Array<TravelInsuranceRequestReportDto>> {
    return this.apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Plain$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<TravelInsuranceRequestReportDto>>) =>
          r.body as Array<TravelInsuranceRequestReportDto>
      )
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Json$Response(params: {
    Authentication: string;
    body?: TravelInsuranceRequestReportInput;
  }): Observable<StrictHttpResponse<Array<TravelInsuranceRequestReportDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsuranceRequestService.ApiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TravelInsuranceRequestReportDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Json(params: {
    Authentication: string;
    body?: TravelInsuranceRequestReportInput;
  }): Observable<Array<TravelInsuranceRequestReportDto>> {
    return this.apiServicesAppTravelInsuranceRequestTravelInsuranceRequestlReportPost$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<TravelInsuranceRequestReportDto>>) =>
          r.body as Array<TravelInsuranceRequestReportDto>
      )
    );
  }
}
