import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ParameterByDomainService, StorageService } from '@app/@core/services';
import { AgentType, SidebarItem } from '@app/interfaces';
import { Sidebar } from 'ng-sidebar';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, AfterViewInit {
  @ViewChild('sidebar') public sidebar!: Sidebar;

  public sidebarMenu$: Observable<AgentType>;
  public showIfIsOpened: boolean;
  public showChat: boolean = false;

  constructor(private _storage: StorageService, private _domains: ParameterByDomainService) {
    this.showIfIsOpened = false;
    this.sidebarMenu$ = this._storage.sidebar$.pipe(
      tap((s) => {
        if (s?.show) {
          this.showIfIsOpened = false;
          this.sidebar?.open();
        } else {
          this.sidebar?.close();
        }
      }),
      map((s) => s?.currentUser ?? 'particular')
    );
  }

  ngOnInit() {
    this._domains.getDomainByTopLevelDomain(this._storage.getTopLevelDomain()).subscribe({
      next: (data) => {
        if (data?.chatChannel !== 0 && data) this.showChat = true;
      },
    });
  }

  ngAfterViewInit(): void {
    if (this._storage.getItemJson<SidebarItem>('sidebarMenu')?.show) {
      this.sidebar.open();
    }
  }
}
