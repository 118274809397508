import { DEFAULT_DOMAINS, DEFAULT_TOP_LEVEL_DOMAIN, DOMAIN_NAME, TEST_DOMAINS } from '@app/common';

export const setUpDomains = () => {
  let topLevelDomain: string;

  if (DEFAULT_DOMAINS.concat(TEST_DOMAINS).includes(DOMAIN_NAME)) {
    topLevelDomain = DEFAULT_TOP_LEVEL_DOMAIN;
  } else {
    const TOP_LEVEL_DOMAIN = DOMAIN_NAME.substring(0, DOMAIN_NAME.indexOf('.'));
    topLevelDomain = TOP_LEVEL_DOMAIN === '' ? DEFAULT_TOP_LEVEL_DOMAIN : TOP_LEVEL_DOMAIN;
  }

  localStorage.setItem('tas_topLevelDomain', topLevelDomain);
  localStorage.setItem('tas_domainName', DOMAIN_NAME);
};
