import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LoaderComponent } from './loader/loader.component';
import { QuoteInformationComponent } from './components/quote-information/quote-information.component';
import { PlanDetailDialogComponent } from './components/plan-detail-dialog/plan-detail-dialog.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [TranslateModule, CommonModule, FormsModule, ReactiveFormsModule],
  providers: [],
  declarations: [LoaderComponent, QuoteInformationComponent, PlanDetailDialogComponent, NewsletterComponent],
  exports: [LoaderComponent, QuoteInformationComponent, PlanDetailDialogComponent, NewsletterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
