/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { QuotationRequestDto, TravelInsuranceCustomerDto } from '../models';
import { ResponseSendEmailPurchaseDto } from '../models/travel-insurance-customer-dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiDocumentsCertificateGet
   */
  static readonly ApiDocumentsCertificateGetPath = '/api/Documents/Certificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentsCertificateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentsCertificateGet$Response(params: {
    pax?: number;
    code?: string;
    tenantId?: number;
    language?: string;
    Authentication: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, DocumentsService.ApiDocumentsCertificateGetPath, 'get');
    if (params) {
      rb.query('pax', params.pax, {});
      rb.query('code', params.code, {});
      rb.query('tenantId', params.tenantId, {});
      rb.query('language', params.language, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentsCertificateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentsCertificateGet(params: {
    pax?: number;
    code?: string;
    tenantId?: number;
    language?: string;
    Authentication: string;
  }): Observable<void> {
    return this.apiDocumentsCertificateGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiDocumentsConsulateGet
   */
  static readonly ApiDocumentsConsulateGetPath = '/api/Documents/Consulate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentsConsulateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentsConsulateGet$Response(params: {
    pax?: number;
    code?: string;
    tenantId?: number;
    language?: string;
    Authentication: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, DocumentsService.ApiDocumentsConsulateGetPath, 'get');
    if (params) {
      rb.query('pax', params.pax, {});
      rb.query('code', params.code, {});
      rb.query('tenantId', params.tenantId, {});
      rb.query('language', params.language, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentsConsulateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentsConsulateGet(params: {
    pax?: number;
    code?: string;
    tenantId?: number;
    language?: string;
    Authentication: string;
  }): Observable<void> {
    return this.apiDocumentsConsulateGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiDocumentsPurchaseOrderGet
   */
  static readonly ApiDocumentsPurchaseOrderGetPath = '/api/Documents/PurchaseOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentsPurchaseOrderGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentsPurchaseOrderGet$Response(params: {
    pax?: number;
    code?: string;
    tenantId?: number;
    language?: string;
    Authentication: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, DocumentsService.ApiDocumentsPurchaseOrderGetPath, 'get');
    if (params) {
      rb.query('pax', params.pax, {});
      rb.query('code', params.code, {});
      rb.query('tenantId', params.tenantId, {});
      rb.query('language', params.language, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentsPurchaseOrderGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentsPurchaseOrderGet(params: {
    pax?: number;
    code?: string;
    tenantId?: number;
    language?: string;
    Authentication: string;
  }): Observable<void> {
    return this.apiDocumentsPurchaseOrderGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiDocumentsContractGet
   */
  static readonly ApiDocumentsContractGetPath = '/api/Documents/Contract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentsContractGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentsContractGet$Response(params: {
    pax?: number;
    code?: string;
    tenantId?: number;
    language?: string;
    Authentication: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, DocumentsService.ApiDocumentsContractGetPath, 'get');
    if (params) {
      rb.query('pax', params.pax, {});
      rb.query('code', params.code, {});
      rb.query('tenantId', params.tenantId, {});
      rb.query('language', params.language, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentsContractGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentsContractGet(params: {
    pax?: number;
    code?: string;
    tenantId?: number;
    language?: string;
    Authentication: string;
  }): Observable<void> {
    return this.apiDocumentsContractGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation apiServicesAppDocumentsSendEmailQuotePost
   */
  static readonly ApiServicesAppDocumentsSendEmailQuotePostPath = '/document/Documents/SendMailQuote/Quotation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppDocumentsSendEmailQuotePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppDocumentsSendEmailQuotePost$Plain$Response(params: {
    travelInsuranceRequestId?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, DocumentsService.ApiServicesAppDocumentsSendEmailQuotePostPath, 'post');
    if (params) {
      rb.query('travelInsuranceRequestId', params.travelInsuranceRequestId, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppDocumentsSendEmailQuotePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppDocumentsSendEmailQuotePost$Plain(params: {
    travelInsuranceRequestId?: number;
    Authentication: string;
  }): Observable<boolean> {
    return this.apiServicesAppDocumentsSendEmailQuotePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppDocumentsSendEmailQuotePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppDocumentsSendEmailQuotePost$Json$Response(Hashcode: string): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, DocumentsService.ApiServicesAppDocumentsSendEmailQuotePostPath, 'post');
    if (Hashcode) {
      rb.body({ Hashcode }, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppDocumentsSendEmailQuotePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppDocumentsSendEmailQuotePost$Json(hasCode: string): Observable<boolean> {
    return this.apiServicesAppDocumentsSendEmailQuotePost$Json$Response(hasCode).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }
  static readonly ApiServicesAppDocumentsSendEmailPurchasePostPath = '/document/Documents/SendMailPurchase/Quotation';

  apiServicesAppQuotationPurchasePost$Json(params: {
    Authentication: string;
    body?: ResponseSendEmailPurchaseDto;
  }): Observable<ResponseSendEmailPurchaseDto> {
    return this.apiSendEmailPurchasePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResponseSendEmailPurchaseDto>) => r.body as ResponseSendEmailPurchaseDto)
    );
  }
  apiSendEmailPurchasePost$Json$Response(params: {
    Authentication: string;
    body?: ResponseSendEmailPurchaseDto;
  }): Observable<StrictHttpResponse<ResponseSendEmailPurchaseDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentsService.ApiServicesAppDocumentsSendEmailPurchasePostPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSendEmailPurchaseDto>;
        })
      );
  }
}
