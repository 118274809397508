import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponseDto, TokenDto } from '@app/@core/api/models';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly token = 'token';

  constructor(private _storage: StorageService, private http: HttpClient) {}

  generateToken(): Observable<AuthResponseDto> {
    const body = {
      keyAccess: environment.apikeys.keyAccess,
      Flag: environment.currentEnviroment,
    };
    return this.http
      .post<AuthResponseDto>(`${environment.serverUrl}/access/token`, body)
      .pipe(tap((Response) => this.saveToken(Response.result)));
  }

  getToken(): string | null {
    return this._storage.getItem(this.token);
  }

  hasToken(): boolean {
    return !!this._storage.getItem(this.token);
  }

  private saveToken(TokenDto: TokenDto): void {
    this._storage.setItem(this.token, TokenDto.access_token);
  }

  removeToken(): void {
    this._storage.deleteItem(this.token);
  }
}
