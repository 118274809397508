import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss'],
})
export class OurServicesComponent implements OnInit {
  responsiveOptions;
  services = [
    {
      title: 'Atención médica por COVID-19',
      content:
        'Te cubrimos los gastos por atención médica para tu diagnóstico, tratamiento, gastos de traslado y repatriación sanitaria en consecuencia de adquirir COVID-19.',
      img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1683562386/TAS/home/man-with-surgical-mask-outdoor-1_fjglrx.webp',
    },
    {
      title: 'Hospitalización',
      content:
        'Una protección integral enfocada en ti. Cubrimos todos los aspectos en tu hospitalización, ya sea por enfermedad, accidente o cualquier otro motivo que te impida continuar tu viaje.',
      img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1683562386/TAS/home/ill-male-patient-in-bed-talking-to-nurse-1_hdhehk.webp',
    },
    {
      title: 'Asistencia médica por accidente o enfermedad',
      content:
        'Disfruta tu viaje sin preocupaciones, te brindamos la mejor atención médica especializada, ya sea por accidente o enfermedad.',
      img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1683562386/TAS/home/team-of-doctors-standing-together-in-hospital-premises-1_dltezv.webp',
    },
    {
      title: 'Cancelación de vuelo',
      content: 'Si cancelan tu vuelo, te reembolsamos los gastos derivados por consecuencia de esta situación. ',
      img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1683562386/TAS/home/airport-timetable-display-1_fdxhck.webp',
    },
    {
      title: 'Telemedicina y video consulta',
      content:
        'Estamos disponibles 24/7 en caso de emergencia, podremos anticipar el diagnóstico junto con sus síntomas y brindarte soluciones para tratarlos.',
      img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1683562386/TAS/home/asian-woman-on-telemedicine-online-video-consultation-medical-online-consulting-therapist-family-car_v0bydh.webp',
    },
    {
      title: 'Repatriación sanitaria',
      content:
        'Te ofrecemos este servicio en caso de que una enfermedad grave te impida continuar con tu viaje, te cubrimos en caso de que necesites regresar de urgencia a tu país.',
      img: 'https://res.cloudinary.com/pervolare-org/image/upload/v1683562386/TAS/home/young-woman-enjoying-view-of-mountains-1_cgmhpu.webp',
    },
  ];
  constructor() {
    this.responsiveOptions = [
      {
        breakpoint: '1460px',
        numVisible: 4,
        numScroll: 1,
      },
      {
        breakpoint: '1366px',
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: '1100px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
        showNavigators: true,
        showIndicators: true,
      },
    ];
  }

  ngOnInit(): void {}
}
